//

export default {
  name: 'ZammadChat',
  mounted() {
    if (this.isInSchedule) {
      this.loadChat()
    }
  },
  methods: {
    loadChat() {
      // https://ticket.reverseproxy.2152.as35518.net/assets/chat/chat.css
      // https://ticket.reverseproxy.2152.as35518.net/assets/chat/chat-no-jquery.min.js
      //
      // https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js
      // https://ticket.reverseproxy.2152.as35518.net/assets/chat/chat.min.js

      let scriptUrl =
        'https://ticket.reverseproxy.2152.as35518.net/assets/chat/chat-no-jquery.min.js'

      this.$loadScript(scriptUrl)
        .then(() => {
          new ZammadChat({
            background: '#00A3DD',
            fontSize: '12px',
            chatId: 1,
            title: 'Chat',
            lang: 'de',
            host: 'wss://ticket.reverseproxy.2152.as35518.net/ws',
          })
        })
        .catch((e) => {
          console.error(`Failed to load Zammad chat widget from server: ${e}`)
        })
    },
  },
  computed: {
    isInSchedule() {
      let schedule = {
        activeWeekDays: [1, 2, 3, 4, 5],
        startTime: '07:50',
        endTime: '18:30',
      }
      let now = new Date()
      let currentWeekDay = now.getDay()
      let isActiveWeekday = schedule.activeWeekDays.indexOf(currentWeekDay) > -1
      let startTime = new Date(`${now.toDateString()} ${schedule.startTime}`)
      let endTime = new Date(`${now.toDateString()} ${schedule.endTime}`)
      let isInTime = now >= startTime && now <= endTime
      return isActiveWeekday && isInTime
    },
  },
}
