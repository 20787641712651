import * as date from 'date-fns'
import { ConnectedSingletonModel } from '@orm'
import { parseProductName } from '@/assets/util/helpers'

export default class Internet extends ConnectedSingletonModel {
  static entity = 'internet'
  static allowEmptyInstance = false

  static pull() {
    return this.api.get`/internet`
  }

  static fields() {
    return {
      aboChangeInProgress: this.boolean(false),
      aboStart: this.string(''),
      additionalPackages: this.attr(() => []),
      bookablePackages: this.attr(() => []),
      isCurrent: this.boolean(true),
      name: this.string(''),
      speed: this.attr(() => ({})),
      modemTyp: this.string(''),
      packageChangeInProgress: this.boolean(false),
      price: this.number(0),
      totalPriceWithPackages: this.number(0),
    }
  }

  get nameParts() {
    return parseProductName(this.name)
  }

  get bandwidth() {
    return {
      down: Number(this.speed.downloadKbit) * 1000,
      up: Number(this.speed.uploadKbit) * 1000,
    }
  }

  get aboStartDate() {
    return date.startOfDay(date.parse(this.aboStart, 'y-MM-dd', new Date()))
  }

  get aboStartMonth() {
    return date.startOfMonth(this.aboStartDate)
  }
}
