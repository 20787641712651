import Vue from 'vue'
import * as models from '@orm/models-provider'
import { ConnectedSingletonModel } from '@orm'
import { camelCase } from 'lodash-es'

let mixinMarker = Symbol.for('plugins/orm/models-mixin')

// Exclude models with static private = true
let modelsObject = Object.fromEntries(
  Object.entries(models).filter(([, model]) => model.private !== true)
)

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  let loadedModels = new Map()

  Vue.mixin({
    computed: {
      $models: () => modelsObject,
    },
    beforeCreate() {
      if (this.$options.mapModels) {
        let entries
        if (Array.isArray(this.$options.mapModels)) {
          entries = this.$options.mapModels.map((modelName) => {
            let isSingleton =
              modelsObject[modelName]?.prototype instanceof
              ConnectedSingletonModel
            let isPlural = modelName.endsWith('s')

            return [
              camelCase(isPlural || isSingleton ? modelName : `${modelName}s`),
              modelName,
            ]
          })
        } else {
          entries = Object.entries(this.$options.mapModels)
        }

        for (let [computedName, modelName] of entries) {
          if (!(modelName in modelsObject)) {
            throw new Error(`Cannot map unknown model "${modelName}"`)
          }

          if (
            modelsObject[modelName].prototype instanceof ConnectedSingletonModel
          ) {
            this.$options.computed[computedName] = () =>
              modelsObject[modelName].get()
          } else {
            this.$options.computed[computedName] = () =>
              modelsObject[modelName].all()
          }
        }
      }
    },
    created() {
      // Automatically load models
      if (Array.isArray(this.$options.loadModels)) {
        for (let modelDeclaration of this.$options.loadModels) {
          let modelName, loadArgs
          if (Array.isArray(modelDeclaration)) {
            modelName = modelDeclaration[0]
            loadArgs = [modelDeclaration[1]]
          } else {
            modelName = modelDeclaration
            loadArgs = []
          }

          if (!(modelName in this.$models)) {
            throw new Error(`Cannot load unknown model "${modelName}"`)
          }

          let serializedLoadArgs = JSON.stringify(loadArgs)

          // Don't load the same model/payload combination more than once per route
          if (
            !loadedModels.has(modelName) ||
            loadedModels.get(modelName) !== serializedLoadArgs
          ) {
            this.$models[modelName].load(...loadArgs)
          }

          loadedModels.set(modelName, serializedLoadArgs)
        }
      }
    },
    destroyed() {
      // Component is a page (only pages have $metaInfo)
      if (this.$metaInfo) {
        // Clear loaded models on page change
        loadedModels.clear()
      }
    },
  })
}
