//
//
//
//
//
//
//
//
//

if (process.browser) {
  window.BUILD_ID = process.env.BUILD_ID
  window.DEPLOY_TAG = process.env.TAG
  window.DEPLOY_COMMIT = process.env.COMMIT
}

export default {
  mounted() {
    // Handle missing files in /_nuxt (due to network errors,
    // likely because the user lost connection)
    this.$services.networkError.listen(() => {
      this.$store.commit('setHintConnectionIssues', true)
    })
  },
}
