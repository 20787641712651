import AbstractConnectionDetails from './_AbstractConnectionDetails'

// A slice of connections for a particular month
// Solved as a subclass as it needs to maintain an independent payload
export default class VoiceConnectionDetails extends AbstractConnectionDetails {
  static entity = 'voiceConnectionDetails'

  static pullData({ from, to, sort, page, perPage }) {
    return this.api.get`/voice/cdr/${from}/${to}?${{
      page,
      perPage,
      sort,
    }}`
  }

  static get rawConnectionsUrl() {
    return this.api
      .url`/voice/${this.payload.number}/cdr/${this.payload.from}/${this.payload.to}`
  }

  static get csvFilename() {
    if (this.payload.to && this.payload.to !== this.payload.from) {
      return `Telefonie-Verbindungen-von-${this.payload.from}-bis-${this.payload.to}.csv`
    } else {
      return `Telefonie-Verbindungen-${this.payload.from}.csv`
    }
  }
}
