//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Flex',
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    gap: {
      type: [Boolean, String],
      default: false,
    },
    column: Boolean,
    stretch: Boolean,
    center: Boolean,
    spread: Boolean,
    end: Boolean,
    wrap: Boolean,
    fill: Boolean,
  },
  computed: {
    classes: (vm) => ({
      gap: Boolean(vm.gap),
      column: vm.column,
      stretch: vm.stretch,
      center: vm.center,
      spread: vm.spread,
      end: vm.end,
      wrap: vm.wrap,
      fill: vm.fill,
    }),
    style: (vm) => ({
      '--flex-gap': typeof vm.gap === 'string' ? vm.gap : undefined,
    }),
  },
}
