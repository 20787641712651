import Vue from 'vue'
import * as date from 'date-fns'
import deLocale from 'date-fns/locale/de'

let mixinMarker = Symbol.for('plugins/filters/datetime')

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    filters: {
      datetime(value, format) {
        return date.format(value, format, { locale: deLocale })
      },
    },
  })
}
