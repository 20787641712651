//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SeparatorIcon from '~/assets/img/chevron-right-small.svg?inline'
import HomeIcon from '~/assets/img/menu-home.svg?inline'
import formatPhoneNumber from '@/assets/util/format-phone-number'

export default {
  components: {
    HomeIcon,
    SeparatorIcon,
  },
  computed: {
    active: (vm) => vm.$route.path === vm.$store.state.title.path,
    title: (vm) =>
      vm.pathParts.length === 0 ? '' : vm.$store.state.title.breadcrumbTitle,
    pathParts() {
      let path = this.$route.path.replace(/^\/*(.*?)\/?$/, '$1')
      if (path.length === 0) return []
      return path.split('/')
    },
    parts() {
      return this.pathParts.slice(0, -1).map((part, index) => {
        let path = `/${this.pathParts
          .slice(0, index + 1)
          .map((part) => `${part}/`)}`
        let title = this.$store.state.title.breadcrumbFolders[part] ?? part

        return {
          path,
          title,
        }
      })
    },
    mappedParts: (vm) =>
      vm.parts
        .map((part) => ({
          ...part,
          title: vm.adjustTitle(part.title),
        }))
        .filter(({ title }) => title.length > 0),
  },
  methods: {
    // Apply rules to individual breadcrumb parts
    adjustTitle(title) {
      if (/^[0-9\s]+$/.test(title)) {
        return formatPhoneNumber(title)
      }

      return title
    },
  },
}
