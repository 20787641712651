// Various types of model caching strategy generators
// A caching strategy is an object and needs to provide the following methods,
// each of which may or may not be asynchronous:
//
// check(payload: any): 0|1|2
// Whether the cache is
// - missed (0)
// - hit but may need an update (1)
// - hit and does not need fresh data (2)
//
// read(payload: any): any
// Fetch the cached data
//
// write(data: any, payload: any): void
// Write data to the cache
//
// clear(payload: any): void
// Clear the model's cache

import { MISS, CAUTIOUS_HIT, DEFINITIVE_HIT } from './caching'

// Not providing any caching at all
export function noop() {
  return {
    check: () => MISS,
    read: () => {},
    write: () => {},
    clear: () => {},
  }
}

/**
 * Create a browser Storage based cache
 * Supports options 'keyPrefix' and 'expires' (in seconds)
 *
 * @param {Storage} storage
 * @returns
 */
function createStorageBasedCache(storage) {
  return function storageBasedCache(
    modelClass,
    { keyPrefix = `model-cache:${modelClass.entity}:`, expires = 0 } = {}
  ) {
    // In rare cases, storage might not be available
    // If that happens, use in-memory cache instead
    if (typeof storage !== 'object') {
      return memory(modelClass, { expires })
    }

    let cacheKey = (payload) => `${keyPrefix}${JSON.stringify(payload)}`

    return {
      check: (payload) => {
        let item = storage.getItem(cacheKey(payload))
        if (typeof item !== 'string') return MISS
        if (expires === 0) return CAUTIOUS_HIT

        let { time, build } = JSON.parse(item)

        if (build !== window.BUILD_ID) return MISS

        let expired = Date.now() - time > expires * 1000
        if (expired) return CAUTIOUS_HIT

        return DEFINITIVE_HIT
      },
      read: (payload) => {
        let result = JSON.parse(storage.getItem(cacheKey(payload)))
        if (!result) return { data: undefined, meta: undefined }

        return { data: result.data, meta: result.meta }
      },
      write: (data, payload, meta) => {
        let key = cacheKey(payload)
        let value = JSON.stringify({
          time: Date.now(),
          build: window.BUILD_ID,
          data,
          meta,
        })

        storage.setItem(key, value)
      },
      clear: () => {
        for (let key of Object.keys(storage)) {
          if (key.startsWith(keyPrefix)) {
            storage.removeItem(key)
          }
        }
      },
    }
  }
}

// Cache in sessionStorage
export const sessionStorage = createStorageBasedCache(window.sessionStorage)

// Cache in localStorage
export const localStorage = createStorageBasedCache(window.localStorage)

// Volatile in-memory cache
export function memory(modelClass, { expires = 0 } = {}) {
  let cache = new Map()
  let cacheKey = (payload) => `${modelClass.entity}:${JSON.stringify(payload)}`

  return {
    check: (payload) => {
      let key = cacheKey(payload)
      if (!cache.has(key)) return MISS
      if (expires === 0) return CAUTIOUS_HIT
      let { time } = cache.get(key)

      let expired = Date.now() - time > expires * 1000
      if (expired) return CAUTIOUS_HIT

      return DEFINITIVE_HIT
    },
    read: (payload) => {
      let result = cache.get(cacheKey(payload))
      if (!result) return { data: undefined, meta: undefined }

      return { data: result.data, meta: result.meta }
    },
    write: (data, payload, meta) => {
      cache.set(cacheKey(payload), { data, meta, time: Date.now() })
    },
    clear: () => {
      cache.clear()
    },
  }
}
