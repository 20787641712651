import { parseProductName } from '@/assets/util/helpers'
import { Model } from '@orm'
import * as date from 'date-fns'

export default class Abo extends Model {
  static private = true
  static entity = 'mobile/abo'

  static fields() {
    return {
      number: this.uid(),
      name: this.string(),
      isCurrent: this.boolean(),
      aboStart: this.string(''),
      price: this.number(),
      included: this.attr(),
      details: this.attr(),
    }
  }

  get nameParts() {
    return parseProductName(this.name)
  }

  get startDate() {
    return date.startOfDay(date.parse(this.aboStart, 'y-MM-dd', new Date()))
  }

  get startMonth() {
    return date.startOfMonth(this.startDate)
  }
}
