import { ConnectedSingletonModel } from '@orm'

export default class InternetTraffic extends ConnectedSingletonModel {
  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static entity = 'internetTraffic'
  static allowEmptyInstance = false

  static pull({ from }) {
    return this.api.get`/internet/traffic/month/${from}`
  }

  static fields() {
    return {
      download: this.attr(
        () => ({ KB: 0, MB: 0, GB: 0 }),
        (value) => (typeof value === 'number' ? value : value.MB)
      ),
      upload: this.attr(
        () => ({ KB: 0, MB: 0, GB: 0 }),
        (value) => (typeof value === 'number' ? value : value.MB)
      ),
    }
  }
}
