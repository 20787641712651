// Provide all modules exported from the /services folder

import { camelCase } from 'lodash-es'

let requireService = require.context(
  '~/services',
  false,
  /(\/|^)[^/-]+[^/]*\.js$/i
)

export default (context, inject) => {
  let services = {}

  for (let key of requireService.keys()) {
    let serviceName = camelCase(key.replace(/^\.\/(.+)\.js$/, '$1'))

    let serviceExports = { ...requireService(key) }
    if (typeof serviceExports.default === 'function') {
      services[serviceName] = serviceExports.default(context)
    } else if (Object.keys(serviceExports).length > 0) {
      services[serviceName] = serviceExports
    }
  }

  context.$services = services
  inject('services', services)
}
