//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// A "renderless" component that represents various kinds of buttons in this app:
// - regular, interactive buttons
// - internal or external links (by passing a 'link' attribute)
// - dropdowns (by passing content for a 'dropdown' slot)
//
// Can be used as the root element of another component to avoid having to implement
// this functionality in multiple places.

import Dropdown from '@ui/Dropdown/Dropdown.vue'

export default {
  name: 'ActionTrigger',
  inheritAttrs: false,
  components: { Dropdown },
  props: {
    link: {
      default: undefined,
    },
    disabled: Boolean,
  },
  computed: {
    hasLink: (vm) => typeof vm.link !== 'undefined',
    hasTextLink: (vm) => typeof vm.link === 'string',
    hasAnchorLink: (vm) => vm.hasTextLink && vm.link.startsWith('#'),
    hasExternalLink: (vm) => vm.hasTextLink && /^https?:\/\//.test(vm.link),
    hasNuxtLink: (vm) => vm.hasLink && !vm.hasAnchorLink && !vm.hasExternalLink,
    receivedDropdownContent: (vm) => typeof vm.$slots.dropdown !== 'undefined',
    component() {
      if (this.receivedDropdownContent) {
        return 'Dropdown'
      } else if (this.disabled) {
        return 'button'
      } else if (this.hasNuxtLink) {
        return 'NuxtLink'
      } else if (this.hasLink) {
        return 'a'
      } else {
        return 'button'
      }
    },
    componentAttrs() {
      if (this.receivedDropdownContent) {
        return { disabled: this.disabled, seamless: true }
      } else if (!this.disabled) {
        // Don't apply hrefs if link is "disabled"

        if (this.hasNuxtLink) {
          return { to: this.link }
        } else if (this.hasLink) {
          let attrs = { href: this.link }

          if ('target' in this.$attrs) {
            attrs.rel = 'noopener'
          }

          return attrs
        } else {
          return { type: 'button' }
        }
      } else {
        return {
          disabled: true,
        }
      }
    },
  },
}
