/**
 * Format a (Swiss) phone number
 *
 * @param {string} numberString
 * @returns {string}
 */
export default function formatPhoneNumber(numberString) {
  let pattern = /^(0041|041|\+41|\+\+41|41)?(0|\(0\))?([1-9]\d{1})(\d{3})(\d{2})(\d{2})$/
  let match = numberString.match(pattern)

  if (match) {
    return `0${match[3]} ${match[4]} ${match[5]} ${match[6]}`
  } else {
    return numberString
  }
}
