import * as date from 'date-fns'

/**
 * Generate a (cryptographically not secure) random string
 *
 * @returns {string}
 */
export const generateRandomId = () =>
  Math.round(Math.random() * 10 ** 9).toString(16)

/**
 * Round a number to a particular precision
 *
 * @param value     The number to round
 * @param precision The number of decimals to round to
 */
export const round = (value, precision = 0) =>
  Math.round(value * 10 ** precision) / 10 ** precision

/**
 * Get the difference between two objects so that a deep merge of the result
 * applied to the 'from' object would result in the 'to' object
 *
 * @param {object} from
 * @param {object} to
 * @returns {object}
 */
export function diffObjects(from, to) {
  if (!to) {
    return null
  }

  let diffed = []
  let keys = Object.keys(to)

  if (keys.length === 0 && Object.keys(from).length === 0) {
    return null
  }

  for (let key of keys) {
    if (!(key in from)) {
      diffed.push([key, to[key]])
    } else if (from[key] !== to[key]) {
      if (typeof to[key] === 'object' && typeof from[key] === 'object') {
        let result = diffObjects(from[key], to[key])

        if (result) {
          diffed.push([key, result])
        }
      } else {
        diffed.push([key, to[key]])
      }
    }
  }

  if (diffed.length === 0) {
    return null
  }

  return Object.fromEntries(diffed)
}

/**
 * Parse a sasag product name of the iconic format "Primary@Scope Secondary"
 *
 * @param {string} name
 * @returns {{ primary: string, scope: string, secondary: string}|undefined}
 */
export function parseProductName(name) {
  let match = name.match(
    /(?<primary>[a-z0-9]+)\s*(?<scope>@.+?) (?<secondary>.+)/i
  )

  if (!match) return undefined

  return match.groups
}

/**
 * Natural sort comparison
 *
 * @param {string} a
 * @param {string} b
 */
export function naturalSort(a, b) {
  return a.localeCompare(b, 'de-CH', { numeric: true })
}

/**
 * Natural sort for <b-table> 'custom-sort' prop
 *
 * @param {string|function} getCellValue Function or prop name to get field from row
 */
export function tableColumnNaturalSort(getCellValue) {
  if (typeof getCellValue === 'string') {
    let key = getCellValue
    getCellValue = (row) => row[key]
  }

  return (a, b, isAsc) =>
    naturalSort(getCellValue(a), getCellValue(b)) * (isAsc ? 1 : -1)
}

/**
 * Numeric sort for <b-table> 'custom-sort' prop
 *
 * @param {string|function} getCellValue Function or prop name to get field from row
 */
export function tableColumnNumericSort(getCellValue) {
  if (typeof getCellValue === 'string') {
    let key = getCellValue
    getCellValue = (row) => row[key]
  }

  return (a, b, isAsc) => (getCellValue(a) - getCellValue(b)) * (isAsc ? 1 : -1)
}

/**
 * Get month Date from the URL
 *
 * @param {object} options
 * @returns {[Date, Date]|null}
 */
export function getMonthRangeFromUrl({
  allowOpenEnd = false,
  allowOpenStart = false,
} = {}) {
  if (!process.browser) return null

  let urlParams = new URLSearchParams(window.location.search)

  let start = urlParams.get('von')
  if (start === null && !allowOpenStart) return null

  let end = urlParams.get('bis')
  if (end === null && !allowOpenEnd) return null

  if (start === null && end === null) return null

  let startDate = null
  if (start !== null) {
    startDate = date.parse(start, 'y-MM', new Date())
    if (isNaN(startDate.getTime())) return null
  }

  let endDate = null
  if (end !== null) {
    endDate = date.parse(end, 'y-MM', new Date())
    if (isNaN(endDate.getTime())) return null
  }

  return [startDate, endDate]
}

/**
 * Reflect month in the URL
 *
 * @param {string} start
 * @param {string} end
 * @param {object} options
 */
export function reflectMonthRangeInUrl(
  start,
  end,
  {
    action = false,
    base = window.location.search,
    omitCurrentMonth = true,
  } = {}
) {
  let urlParams = new URLSearchParams(base)

  let currentUrlStart = urlParams.get('von')
  let currentUrlEnd = urlParams.get('bis')
  if (currentUrlStart == start && currentUrlEnd == end) return

  let nowMonth = date.format(date.startOfMonth(new Date()), 'y-MM')

  if (start == null) {
    urlParams.delete('von')
  } else if (!omitCurrentMonth || nowMonth !== start) {
    urlParams.set('von', start)
  }

  if (end == null) {
    urlParams.delete('bis')
  } else if (!omitCurrentMonth || nowMonth !== end) {
    urlParams.set('bis', end)
  }

  let queryString = urlParams.toString()
  if (queryString.length > 0) {
    queryString = `?${queryString}`
  }

  if (action === 'push') {
    window.history.pushState(null, null, queryString)
  } else if (action === 'replace') {
    window.history.replaceState(null, null, queryString)
  }

  return queryString
}

/**
 * Create a seeded random number generator
 *
 * @param {seed} The seed for the RNG
 * @returns A function which generates a series of random numbers between 0 and 1
 *
 * @example
 * ```
 * const rand1 = createRNG('apple')
 * rand1() // 0.26642920868471265
 * rand1() // 0.0003297457005828619
 * rand1() // 0.2232720274478197
 *
 * // Same seed generates same series of random values
 * const rand2 = createRNG('apple')
 * rand2() // 0.26642920868471265
 * rand2() // 0.0003297457005828619
 * rand2() // 0.2232720274478197
 * ```
 *
 * @see https://stackoverflow.com/a/47593316/2048874 for individual code parts
 */
export function createRNG(seed = Math.random()) {
  function xmur3(string) {
    for (var i = 0, h = 1779033703 ^ string.length; i < string.length; i++) {
      h = Math.imul(h ^ string.charCodeAt(i), 3432918353)
      h = (h << 13) | (h >>> 19)
    }

    return () => {
      h = Math.imul(h ^ (h >>> 16), 2246822507)
      h = Math.imul(h ^ (h >>> 13), 3266489909)
      return (h ^= h >>> 16) >>> 0
    }
  }

  function mulberry32(seed) {
    return function () {
      let t = (seed += 0x6d2b79f5)
      t = Math.imul(t ^ (t >>> 15), t | 1)
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296
    }
  }

  const seeder = xmur3(String(seed))
  return mulberry32(seeder())
}

/**
 * Get the according redirect URL to the current page
 *
 * @param {object} options
 */
export function getRedirectUrl({ leadingSlash = true } = {}) {
  let currentUrl =
    window.location.pathname + window.location.search + window.location.hash

  let result
  if (currentUrl === '/') {
    result = ''
  } else {
    let urlSearchParams = new URLSearchParams([['redirect', currentUrl]])
    result = `?${urlSearchParams}`
  }

  return leadingSlash ? `/${result}` : result
}

/**
 * Copy a string to the user's clipboard
 *
 * @param {string} text
 */
export function copyText(text) {
  let input = document.createElement('textarea')
  input.value = text
  input.className = 'sr-only'
  input.setAttribute('aria-hidden', 'true')
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  input.remove()
}

/**
 * Check whether the current browser supports a given selector
 *
 * @param {string} selector
 * @returns {boolean}
 */
export function supportsSelector(selector) {
  let style = document.createElement('style')
  document.head.appendChild(style)
  let sheet = style.sheet

  try {
    sheet.insertRule(`${selector} {}`)
    return true
  } catch {
    return false
  } finally {
    style.remove()
  }
}

/**
 * Poll for anything
 *
 * @param {() => boolean} check
 * @param {number} interval
 * @param {number|undefined} timeout
 * @returns {Promise<void>}
 */
export function poll(check, interval, timeout) {
  return new Promise((resolve, reject) => {
    if (check()) {
      resolve()
      return
    }

    let timeoutID
    let intervalID = setInterval(() => {
      if (check()) {
        if (timeout) {
          clearTimeout(timeoutID)
        }

        clearInterval(intervalID)
        resolve()
      }
    }, interval)

    if (timeout) {
      timeoutID = setTimeout(() => {
        clearInterval(intervalID)
        reject()
      }, timeout)
    }
  })
}

/**
 * Resolve HTML entities in a string (only client-side)
 *
 * @param {escapedString} string The escaped string with HTML entities
 * @returns {string} The unescaped string with HTML entities resolved
 */
export function unescapeHtml(escapedString) {
  let helperElement = document.createElement('div')
  helperElement.innerHTML = escapedString
  return helperElement.innerText
}
