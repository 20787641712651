import { ConnectedSingletonModel } from '@orm'
import Summary from './connections/Summary'

// ATTENTION: This should be treated as an abstract class
export default class AbstractConnectionSummary extends ConnectedSingletonModel {
  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static allowEmptyInstance = false

  static categories = [
    'landline-domestic',
    'mobile-domestic',
    'landline-foreign',
    'mobile-foreign',
    'mobile-roaming',
    'special',
    'other',
  ]

  static fields() {
    return {
      'Festnetz Schweiz': this.attr(),
      'Mobile Schweiz': this.attr(),
      'Festnetz Ausland': this.attr(),
      Roaming: this.attr(),
      'Mobile Ausland': this.attr(),
      Spezialnummern: this.attr(),
      'nicht zugeordnet': this.attr(),
    }
  }

  'landline-domestic' = new Summary(this['Festnetz Schweiz'])
  'mobile-domestic' = new Summary(this['Mobile Schweiz'])
  'landline-foreign' = new Summary(this['Festnetz Ausland'])
  'mobile-roaming' = new Summary(this['Roaming'])
  'mobile-foreign' = new Summary(this['Mobile Ausland'])
  special = new Summary(this['Spezialnummern'])
  other = new Summary(this['nicht zugeordnet'])

  get costSum() {
    return this.constructor.categories.reduce(
      (carry, category) => carry + this[category].cost,
      0
    )
  }

  get durationSum() {
    return this.constructor.categories.reduce(
      (carry, category) => carry + (this[category].duration || 0),
      0
    )
  }
}
