/**
 * No cached value was found
 */
export const MISS = 0

/**
 * A cached value was found but should be updated
 */
export const CAUTIOUS_HIT = 1

/**
 * A cached value was found and can unanimously be used
 */
export const DEFINITIVE_HIT = 2
