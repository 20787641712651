//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonArrow from '~/assets/img/button-arrow-right.svg?inline'
import ExternalArrow from '~/assets/img/external-alt.svg?inline'

export default {
  name: 'Button',
  components: { ButtonArrow, ExternalArrow },
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    progress: Number,
    withArrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasProgress: (vm) => typeof vm.progress === 'number',
  },
  methods: {
    triggerClick(event) {
      if (
        this.$slots.dropdown &&
        !event.target.matches('.button .header, .button .header *')
      ) {
      }
    },
  },
}
