import { ConnectedModel } from '@orm'

export default class Recommendation extends ConnectedModel {
  static entity = 'recommendation'

  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static async pull() {
    return await this.api.get`/product/recommended`
  }

  static fields() {
    return {
      id: this.uid(),
      date: this.string(''),
      category: this.string(''),
      billed: this.boolean(false),
      credit: this.number(0),
      text: this.string(''),
    }
  }

  get formattedDate() {
    return this.date.split('-').reverse().join('.')
  }

  get formattedCategory() {
    return (
      {
        internet: 'Internet',
        mobile: 'Mobile',
        tv: 'TV',
      }[this.category] ?? null
    )
  }

  get categoryAccentColor() {
    return (
      {
        internet: 'pink',
        mobile: 'orange',
        tv: 'green',
      }[this.category] ?? 'grey'
    )
  }

  static get sum() {
    return this.all().reduce((sum, { credit }) => sum + credit, 0)
  }
}
