// Apply metadata in the format which Laravel returns for paginated responses

export const defaultMeta = {
  page: null,
  firstPageUrl: null,
  from: null,
  lastPage: null,
  lastPageUrl: null,
  links: null,
  nextPageUrl: null,
  path: null,
  perPage: null,
  prevPageUrl: null,
  to: null,
  total: null,
}

export function applyResponse(meta, response) {
  Object.assign(meta, {
    page: response.current_page ?? null,
    firstPageUrl: response.first_page_url ?? null,
    from: response.from ?? null,
    lastPage: response.last_page ?? null,
    lastPageUrl: response.last_page_url ?? null,
    links: response.links ?? null,
    nextPageUrl: response.next_page_url ?? null,
    path: response.path ?? null,
    perPage: response.per_page ?? null,
    prevPageUrl: response.prev_page_url ?? null,
    to: response.to ?? null,
    total: response.total ?? null,
  })
}
