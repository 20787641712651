import { ConnectedModel } from '@orm'

export default class Product extends ConnectedModel {
  static entity = 'product'

  static async pull() {
    let data = await this.api.get`/product`
    return data.map((id) => ({ id }))
  }

  static fields() {
    return {
      id: this.uid(),
    }
  }
}
