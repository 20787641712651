import { ConnectedSingletonModel } from '@orm'
import * as date from 'date-fns'

export default class Voice extends ConnectedSingletonModel {
  static entity = 'voice'
  static allowEmptyInstance = false

  static pull() {
    return this.api.get`/voice`
  }

  static async updatePackages(packages) {
    await this.api.post('/voice', { voicePackages: packages })
    await this.load({ ...this.payload, refresh: true })
  }

  static fields() {
    return {
      aboChangeInProgress: this.boolean(false),
      aboStart: this.string(''),
      additionalPackages: this.attr(() => []),
      bookablePackages: this.attr(
        () => ({}),
        (value) =>
          Array.isArray(value)
            ? value
            : Object.entries(value).map(([id, packages]) => ({
                id,
                packages,
              }))
      ),
      isCurrent: this.boolean(true),
      name: this.string(''),
      numbers: this.attr(() => []),
      packageChangeInProgress: this.boolean(false),
      price: this.number(),
      totalPriceWithPackages: this.number(0),
    }
  }

  uniquePackages = this.additionalPackages.filter(
    (additionalPackage) => additionalPackage.voicePackageCategory === ''
  )
  additionalPackagesByCategory = this.additionalPackages.reduce(
    (carry, additionalPackage) => ({
      ...carry,
      [additionalPackage.voicePackageCategory]: additionalPackage,
    }),
    {}
  )

  get packageCategories() {
    return [...new Set([...this.bookablePackages.map(({ id }) => id)])]
  }

  get hasBookablePackages() {
    return this.packageCategories.length > 0
  }

  // bookablePackageGroups = Object.entries(
  //   this.bookablePackages
  // ).map(([id, packages]) => ({ id, packages }))

  get aboStartDate() {
    return date.startOfDay(date.parse(this.aboStart, 'y-MM-dd', new Date()))
  }

  get aboStartMonth() {
    return date.startOfMonth(this.aboStartDate)
  }
}
