import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6177c4a9&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Spinner.vue').default,ContactDataPopUp: require('/builds/sasag-api/kundenbereich-frontend/components/modules/modal/ContactDataPopUp.vue').default,RateLimitPopUp: require('/builds/sasag-api/kundenbereich-frontend/components/modules/modal/RateLimitPopUp.vue').default,ConnectionIssuesPopUp: require('/builds/sasag-api/kundenbereich-frontend/components/modules/modal/ConnectionIssuesPopUp.vue').default,ServerIssuesPopUp: require('/builds/sasag-api/kundenbereich-frontend/components/modules/modal/ServerIssuesPopUp.vue').default,BackendHeader: require('/builds/sasag-api/kundenbereich-frontend/components/layout/BackendHeader.vue').default,MobileMenu: require('/builds/sasag-api/kundenbereich-frontend/components/layout/MobileMenu.vue').default,Breadcrumb: require('/builds/sasag-api/kundenbereich-frontend/components/layout/Breadcrumb.vue').default,PageHeading: require('/builds/sasag-api/kundenbereich-frontend/components/layout/PageHeading.vue').default,Footer: require('/builds/sasag-api/kundenbereich-frontend/components/layout/Footer.vue').default,ZammadChat: require('/builds/sasag-api/kundenbereich-frontend/components/layout/ZammadChat.vue').default})
