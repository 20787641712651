// Part of auth detection & redirect
// If this middleware is not executed, that means that
// the 'auth' middleware performed a redirect

import { LAST_REQUESTED_ROUTE } from '@/assets/util/last-requested-route'

export default function () {
  // Reset last requested route
  window[LAST_REQUESTED_ROUTE] = null
}
