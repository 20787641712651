import Vue from 'vue'

let mixinMarker = Symbol.for('plugins/debug')

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    computed: {
      $log: (...data) => console.log(...data),
      $window: () => window,
      $alert: (message) => {
        alert(message)
      },
    },
  })
}
