import { ConnectedModel } from '@orm'

export default class EmailSpamFilterLevels extends ConnectedModel {
  static entity = 'emailSpamFilterLevels'

  static pull() {
    return this.api.get`/email/spamFilterLevels`
  }

  static fields() {
    return {
      name: this.uid(),
      nameDE: this.string(),
      descriptionDE: this.string(),
      tag: this.number(),
      kill: this.number(),
    }
  }
}
