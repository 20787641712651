// Provide all models exported from the /models folder by their entities

let { Model } = require('@vuex-orm/core')

let requireModel = require.context('~/models', true, /(\/|^)[^/_]+[^/]*\.js$/i)

let models = {}
for (let key of requireModel.keys()) {
  let moduleExports = { ...requireModel(key) }
  for (let value of Object.values(moduleExports)) {
    if (value?.prototype instanceof Model) {
      if (value.entity in models) {
        throw new Error(`Model "${value.entity}" cannot be re-registered`)
      }

      models[value.entity] = value
    }
  }
}

module.exports = models
