import Vue from 'vue'

let mixinMarker = Symbol.for('plugins/filters/duration')

const SECONDS_PER_MINUTE = 60
const MINUTES_PER_HOUR = 60

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    filters: {
      duration(seconds) {
        let minutes = Math.floor(seconds / SECONDS_PER_MINUTE)
        seconds -= minutes * SECONDS_PER_MINUTE
        let hours = Math.floor(minutes / MINUTES_PER_HOUR)
        minutes -= hours * MINUTES_PER_HOUR

        let hoursString = String(hours).padStart(2, '0')
        let minutesString = String(minutes).padStart(2, '0')
        let secondsString = String(seconds).padStart(2, '0')

        return `${hoursString}:${minutesString}:${secondsString}`
      },
    },
  })
}
