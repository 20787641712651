//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BarButton',
  inheritAttrs: false,
  props: {
    link: {
      type: String,
      default: undefined,
    },
    vertical: Boolean,
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: String,
  },
}
