import { ConnectedModel } from '@orm'

export default class Invoice extends ConnectedModel {
  static entity = 'invoice'

  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static pull() {
    return this.api.get`/invoice`
  }

  static fields() {
    return {
      id: this.uid(),
      created: this.string(''),
      paymentState: this.string('due'),
      totalPrice: this.string('0', (value) =>
        typeof value === 'number' ? value : parseFloat(value)
      ),
    }
  }

  get formattedDate() {
    if (!this.created) return ''
    return this.created.split('-').reverse().join('.')
  }

  get state() {
    return (
      {
        due: 'Offen',
        paid: 'Bezahlt',
        overdue: 'Überfällig',
        overdue2: 'Überfällig',
        locked: 'Gesperrt',
      }[this.paymentState] ?? 'Unbekannt'
    )
  }

  get stateTune() {
    return (
      {
        due: 'neutral',
        paid: 'positive',
        overdue: 'negative',
        overdue2: 'negative',
        locked: 'negative',
      }[this.paymentState] ?? 'neutral'
    )
  }

  async fetchPdf({ onProgress } = {}) {
    return await this.constructor.api.get(
      this.constructor.api.url`/invoice/${this.id}/pdf`,
      {
        responseType: 'blob',
        onDownloadProgress(event) {
          if (typeof onProgress === 'function') {
            onProgress(event.loaded / event.total)
          }
        },
      }
    )
  }

  // Download the document in the background and generate a BLOB link
  async generateDownloadLink() {
    return URL.createObjectURL(await this.fetchPdf())
  }

  async download({
    filename = `Rechnung sasag ${this.id}.pdf`,
    onProgress,
  } = {}) {
    let blob = await this.fetchPdf({ onProgress })
    let url = URL.createObjectURL(blob)

    let a = document.createElement('a')
    a.download = filename
    a.href = url
    a.click()

    URL.revokeObjectURL(url)
  }
}
