//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    disabled: true,
  }),
  computed: {
    visible: (vm) => vm.$store.state.rateLimitWarning,
  },
  watch: {
    visible(visible) {
      if (!visible) {
        this.disabled = true
      }
    },
  },
}
