import { render, staticRenderFns } from "./error.vue?vue&type=template&id=499ace22&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginHeader: require('/builds/sasag-api/kundenbereich-frontend/components/layout/LoginHeader.vue').default,Button: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Buttons/Button.vue').default})
