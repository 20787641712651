import Vue from 'vue'
import formatSize from '@/assets/util/format-size'

let mixinMarker = Symbol.for('plugins/filters/filesize')

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    filters: {
      filesize: (value, options) => formatSize(value, 'filesize', options),
      bandwidth: (value, options) => formatSize(value, 'bandwidth', options),
    },
  })
}
