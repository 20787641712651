//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: (vm) => ({
    remaining: vm.seconds,
    active: true,
  }),
  props: {
    seconds: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'var(--accent-color--blue)',
    },
    inactiveColor: {
      type: String,
      default: 'var(--accent-color--grey)',
    },
    size: {
      type: Number,
      default: 36,
    },
    stroke: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    chartData: (vm) => [
      {
        id: 'empty',
        value: vm.seconds - vm.remaining,
        background: vm.inactiveColor,
      },
      {
        id: 'filled',
        value: vm.remaining,
        background: vm.color,
      },
    ],
  },
  mounted() {
    this.reset()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    reset() {
      this.remaining = this.seconds
      this.active = true

      this.intervalId = setInterval(() => {
        if (this.remaining === 0) {
          this.active = false
          clearInterval(this.intervalId)
          return
        }

        this.remaining--

        if (this.remaining === 0) {
          this.$emit('done')
        }
      }, 1000)
    },
  },
}
