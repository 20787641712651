const CONSOLE_STYLE = [
  'margin-top: 0.3rem',
  'margin-bottom: 0.3rem',
  'padding: 3px 8px',
  'color: black',
  'background-color: #F8DC3D',
  'border-radius: 3px',
  'font-weight: bold',
  'display: block',
].join(';')
const CONSOLE_OFF_STYLE = 'line-height: 1.6'

/**
 * A channel for broadcasting data to other tabs/windows/frames of the app
 * Uses BroadcastChannel if available, falls back to using service worker events
 *
 * The fallback requires the /static/broadcast-sw.js script to be run in the service worker
 */
export default class CrossChannel {
  constructor(
    name,
    { useServiceWorker = !('BroadcastChannel' in window) } = {}
  ) {
    this.name = name
    this.useServiceWorker = useServiceWorker

    console.debug(
      '%cCROSS CHANNEL%c\nInitialized channel %o in client (using %s)',
      CONSOLE_STYLE,
      CONSOLE_OFF_STYLE,
      name,
      useServiceWorker ? 'the Service Worker' : 'BroadcastChannel'
    )

    if (!this.useServiceWorker) {
      this.channel = new BroadcastChannel(name)
    }
  }

  /**
   * Send a message
   *
   * @param {any} message
   */
  send(message) {
    if (!navigator.serviceWorker) {
      console.warn(
        'Could not send to CrossChannel connection because there is no service worker'
      )
      return
    }

    console.debug(
      '%cCROSS CHANNEL%c\nSend in channel %o: %o',
      CONSOLE_STYLE,
      CONSOLE_OFF_STYLE,
      this.name,
      message
    )

    if (this.useServiceWorker) {
      navigator.serviceWorker.controller?.postMessage({
        broadcast: {
          channel: this.name,
          message,
        },
      })
    } else {
      this.channel.postMessage(message)
    }
  }

  /**
   * Receive a message
   *
   * @param {(message: any) => void} callback
   * @returns {() => void}
   */
  receive(callback) {
    let wrappedCallback
    if (this.useServiceWorker) {
      if (!navigator.serviceWorker) {
        console.warn(
          'Could not listen to CrossChannel connection because there is no service worker'
        )
        return
      }

      wrappedCallback = (event) => {
        if (
          'broadcast' in event.data &&
          event.data.broadcast.channel === this.name
        ) {
          console.debug(
            '%cCROSS CHANNEL%c\nReceived in channel %o: %o',
            CONSOLE_STYLE,
            CONSOLE_OFF_STYLE,
            this.name,
            event.data.broadcast.message
          )

          callback(event.data.broadcast.message)
        }
      }

      navigator.serviceWorker.addEventListener('message', wrappedCallback)
    } else {
      wrappedCallback = (event) => {
        console.debug(
          '%cCROSS CHANNEL%c\nReceived in channel %o: %o',
          CONSOLE_STYLE,
          CONSOLE_OFF_STYLE,
          this.name,
          event.data
        )

        callback(event.data)
      }

      this.channel.addEventListener('message', wrappedCallback)
    }

    return () => {
      if (this.useServiceWorker) {
        navigator.serviceWorker.removeEventListener('message', wrappedCallback)
      } else {
        this.channel.removeEventListener('message', wrappedCallback)
      }
    }
  }
}
