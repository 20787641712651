import { render, staticRenderFns } from "./ServerIssuesPopUp.vue?vue&type=template&id=d3bca76c&scoped=true&"
import script from "./ServerIssuesPopUp.vue?vue&type=script&lang=js&"
export * from "./ServerIssuesPopUp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3bca76c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Buttons/Button.vue').default,Flex: require('/builds/sasag-api/kundenbereich-frontend/components/layout/Flex.vue').default,PopUp: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Dialog/PopUp.vue').default})
