//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BackIcon from '~/assets/img/chevron-left.svg?inline'
import EnvelopeIcon from '~/assets/img/envelope.svg?inline'
import HeadsetIcon from '~/assets/img/headset.svg?inline'

export default {
  components: { BackIcon, EnvelopeIcon, HeadsetIcon },
}
