import { ConnectedModel } from '@orm'
import { unescapeHtml } from '@/assets/util/helpers'

export default class News extends ConnectedModel {
  static entity = 'news'

  // Set cache to only pull every 15 minutes
  // Since this is non-sensitive data, it can be put into localStorage
  static cache = ['localStorage', { expires: 15 * 60 }]

  static async pull() {
    let { 'news-wpce': news } = await this.api.get`/static`

    return news.map((item) => ({
      id: item.id,
      dateString: item.date,
      slug: item.slug,
      link: item.link,
      title: item.title,
      content: item.content,
    }))
  }

  static fields() {
    return {
      id: this.uid(),
      dateString: this.string(),
      slug: this.string(),
      link: this.string(),
      title: this.string('', (value) => unescapeHtml(value)),
      content: this.string(),
    }
  }

  get date() {
    return new Date(this.dateString)
  }

  get contentHtml() {
    return this.content
  }
}
