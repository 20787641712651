//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    to: {
      type: String,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    proxiedListeners() {
      let { click, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    click(event) {
      this.$emit('click')

      if (this.closeOnClick) {
        this.$el.dispatchEvent(
          new CustomEvent('dropdown:handled', { bubbles: true })
        )
      }
    },
  },
}
