import { ConnectedModel } from '@orm'
import * as laravel from '@/assets/util/laravel-pagination'
import * as date from 'date-fns'

// ATTENTION: This should be treated as an abstract class
export default class AbstractConnectionDetails extends ConnectedModel {
  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static allowEmptyInstance = false

  static defaultMeta = {
    ...laravel.defaultMeta,
  }

  static async pull(payload) {
    let response = await this.pullData(payload)

    if (!response) {
      for (let key in this.meta) {
        delete this.meta[key]
      }
      return []
    }

    laravel.applyResponse(this.meta, response)

    return response.data
  }

  static fields() {
    return {
      from: this.string(''),
      to: this.string(''),
      callTime: this.string(''),
      durationSeconds: this.number(0),
      destination: this.string(''),
      destinationGroup: this.string(''),
      cost: this.string(''),
    }
  }

  static async fetchCsv({ onProgress } = {}) {
    return await this.api.get(this.rawConnectionsUrl, {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
      onDownloadProgress(event) {
        if (typeof onProgress === 'function') {
          onProgress(event.loaded / event.total)
        }
      },
    })
  }

  // Download the document in the background and generate a BLOB link
  static async generateDownloadLink() {
    return URL.createObjectURL(await this.fetchCsv())
  }

  static async download({ filename, onProgress } = {}) {
    if (typeof filename === 'undefined') {
      filename = this.csvFilename
    }

    let blob = await this.fetchCsv({ onProgress })
    let url = URL.createObjectURL(blob)

    let a = document.createElement('a')
    a.download = filename
    a.href = url
    a.click()

    URL.revokeObjectURL(url)
  }

  get callTimeDate() {
    return date.parse(this.callTime, 'y-MM-dd HH:mm:ss', new Date())
  }
}
