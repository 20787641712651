//
//
//
//
//

import LogoImage from '~/assets/img/logo.svg?inline'
import LogoImageMonochrome from '~/assets/img/logo-monochrome.svg?inline'

export default {
  name: 'Logo',
  components: { LogoImage, LogoImageMonochrome },
  props: {
    monochrome: Boolean,
  },
}
