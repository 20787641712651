import { Model } from '@orm'

export default class EmailAddress extends Model {
  static entity = 'email/address'

  static fields() {
    return {
      user: this.string(),
      domain: this.string(),
      email: this.string(),
      quota: this.number(),
      quotaUsed: this.number(),
      status: this.string(),
      forward: this.string(),
      spamFilterLevel: this.attr(),
    }
  }

  /**
   * @see {@link https://doc.api.intra.sasag.ch/#put-/email/-email-}
   */
  async update(payload) {
    await $nuxt.$services.api.put(`/email/${this.email}`, payload)
    await $nuxt.$models.email.load({ refresh: true })
  }

  /**
   * @see {@link https://doc.api.intra.sasag.ch/#delete-/email/-email-}
   */
  async delete() {
    await $nuxt.$services.api.delete(`/email/${this.email}`)
    await $nuxt.$models.email.load({ refresh: true })
  }
}
