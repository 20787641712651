// vue-reactivator implementation for running in standalone mode

let matcher = window.matchMedia('(display-mode: standalone)')

export function getSsrValue() {
  return false
}

export function getInitialState() {
  return matcher.matches
}

export function listen(setState) {
  const listener = () => setState(matcher.matches)

  matcher.addListener(listener)

  return () => {
    matcher.removeListener(listener)
  }
}
