//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AlertWarning from '~/assets/img/alert-warning.svg?inline'
import AlertCheck from '~/assets/img/alert-check.svg?inline'
import AlertInfo from '~/assets/img/info.svg?inline'
import AlertClose from '~/assets/img/alert-close.svg?inline'

export default {
  components: {
    AlertWarning,
    AlertCheck,
    AlertInfo,
    AlertClose,
  },
  props: {
    type: {
      type: String,
      default: 'notice',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: undefined,
    },
    inverted: Boolean,
    preventsSubmit: Boolean,
  },
  data() {
    return {
      hasModel: !(typeof this.value === 'undefined'),
    }
  },
  computed: {
    classes: (vm) => [`style-${vm.type}`, { 'style-inverted': vm.inverted }],
    isVisible() {
      return !this.hasModel || this.value
    },
  },
  methods: {
    show() {
      this.$emit('input', true)
    },
    close() {
      this.$emit('input', false)
    },
  },
}
