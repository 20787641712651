import { ConnectedSingletonModel } from '@orm'
import EmailAddress from './EmailAddress'

export default class Email extends ConnectedSingletonModel {
  static entity = 'email'
  static allowEmptyInstance = false

  static pull() {
    return this.api.get`/email`
  }

  static fields() {
    return {
      numberOfEMailAddressesFromInternetAbo: this.number(),
      additionalEmailsFromAbo: this.number(),
      numberOfEmailRentalObjects: this.number(),
      totalNumberOfEmailAddresses: this.number(),
      totalNumberOfEmailAddressesFree: this.number(),
      totalNumberOfEmailAddressesUsed: this.number(),
      addresses: this.attr(
        () => [],
        (value) => {
          if (value.length > 0 && value[0] instanceof EmailAddress) return value

          return value
            .map((record) => new EmailAddress(record))
            .sort((a, b) => a.email.localeCompare(b.email))
        }
      ),
    }
  }

  /**
   * @see {@link https://doc.api.intra.sasag.ch/#post-/email}
   */
  static async createAddress(payload) {
    await $nuxt.$services.api.post('/email', payload)
    await $nuxt.$models.email.load({ refresh: true })
  }
}
