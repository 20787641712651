var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"donut-chart",style:({
    '--size': (_vm.size + "px"),
    '--stroke': (_vm.stroke + "px"),
    '--label-size': _vm.actualLabelSize,
  })},[_c('svg',{staticClass:"donut-graph",attrs:{"height":_vm.size,"width":_vm.size,"viewBox":("0 0 " + _vm.size + " " + _vm.size),"aria-hidden":"true"}},[_vm._l((_vm.data),function(item,index){return _c('circle',{key:("bar-" + (item.id)),style:({
        stroke:
          item.background || ("hsl(" + (index * _vm.autoHueStepSize) + "deg 100% 40%)"),
      }),attrs:{"cx":_vm.cx,"cy":_vm.cy,"r":_vm.radius,"stroke-width":_vm.stroke,"stroke-dasharray":_vm.circumference,"stroke-dashoffset":_vm.calculateStrokeDashOffset(item.value, _vm.circumference),"fill":"transparent","transform":_vm.returnCircleTransformValue(index)}})}),_vm._v(" "),_vm._l((_vm.data),function(item,index){return [(item.label)?_c('text',{key:("label-" + (item.id)),staticClass:"donut-label",style:({ fill: item.foreground || '#fff' }),attrs:{"text-anchor":"middle","dy":"5px","dx":"2px","x":_vm.metadata[index].textX,"y":_vm.metadata[index].textY}},[_vm._v("\n        "+_vm._s(item.label === true ? _vm.percentageString(item.value) : item.label)+"\n      ")]):_vm._e()]})],2),_vm._v(" "),(_vm.$slots.default)?_c('div',{staticClass:"donut-content"},[_c('div',[_vm._t("default")],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }