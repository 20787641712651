//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reactivator from 'vue-reactivator'
import { viewportSize } from 'vue-browser-state'
import * as standalone from '@/assets/util/standalone'
import moreIcon from '~/assets/img/menu-more.svg?raw'

export default {
  name: 'MobileMenu',
  mixins: [reactivator({ viewportSize, standalone })],
  data: () => ({
    moreIcon,
  }),
  computed: {
    mobileMenu: (vm) =>
      vm.$store.getters['menu/items'].filter((item) => item.showOnMobile),
    itemsCount: (vm) => vm.mobileMenu.length,
    viewportHeight: (vm) => vm.viewportSize[1],
    tallScreen: (vm) => vm.viewportHeight > 700,
  },
  methods: {
    showMore() {
      // TODO: Implement show more feature
      alert('Show more!')
    },
  },
}
