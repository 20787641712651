import { ConnectedModel } from '@orm'

export default class Ticket extends ConnectedModel {
  static entity = 'ticket'

  static async pull() {
    return await this.api.get`/support`
  }

  static fields() {
    return {
      id: this.uid(),
      number: this.string(),
      title: this.string(),
      state: this.string(),
      customer: this.string(),
      created: this.string(),
    }
  }

  static async createTicket(subject, text) {
    await this.api.post('/support', { subject, text })
  }
}
