import { render, staticRenderFns } from "./BackendHeader.vue?vue&type=template&id=73268ac9&scoped=true&"
import script from "./BackendHeader.vue?vue&type=script&lang=js&"
export * from "./BackendHeader.vue?vue&type=script&lang=js&"
import style0 from "./BackendHeader.vue?vue&type=style&index=0&id=73268ac9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73268ac9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Buttons/Button.vue').default,Logo: require('/builds/sasag-api/kundenbereich-frontend/components/layout/Logo.vue').default,BarButton: require('/builds/sasag-api/kundenbereich-frontend/components/layout/BarButton.vue').default,DropdownItem: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Dropdown/DropdownItem.vue').default,Dropdown: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Dropdown/Dropdown.vue').default,Header: require('/builds/sasag-api/kundenbereich-frontend/components/layout/Header.vue').default})
