import ConnectedModel from './ConnectedModel'

// Symbols are used as hidden class property names
const EMPTY_INSTANCE = Symbol('Observable data for a connected model')

/**
 * A connected singleton model has an external source that can (optionally)
 * be updated but, cannot have more than one instance.
 * A good example are an app's settings.
 *
 * Since VueX ORM models need an identifier, the model's 'entity' property
 * is used as the singleton instance's $id (called "static identifier" here).
 */
class ConnectedSingletonModel extends ConnectedModel {
  // If set to false, the singleton get() method returns `null`
  // instead of a generic model instance with default properties
  // TODO: The switch from an empty instance to a filled one is not reactive yet.
  // This should be fixed and currently needs a workaround.
  static allowEmptyInstance = true

  // Get the singleton model instance
  static get() {
    let result = this.store().getters['entities/find'](this, this.entity)

    if (result) {
      return result
    }

    if (this.allowEmptyInstance) {
      if (!this[EMPTY_INSTANCE]) {
        this[EMPTY_INSTANCE] = new this()
      }

      return this[EMPTY_INSTANCE]
    }

    return null
  }

  // Apply the static identifier to freshly created models
  static beforeCreate(model) {
    model.$id = this.entity
  }

  // Apply the static identifier to updated models
  static beforeUpdate(model) {
    model.$id = this.entity
  }

  // Use static identifier for updating
  static async update(data) {
    return await super.update({
      where: this.entity,
      data,
    })
  }
}

// VueX ORM does not know the concept of singletons,
// so we manually disable inappropriate methods.
function singletonError() {
  throw new Error(`Method "${this}" not supported for singleton models.`)
}

for (let method of [
  'all',
  'create',
  'find',
  'findIn',
  'query',
  'exists',
  'insert',
  'insertOrUpdate',
  'delete',
  'deleteAll',
]) {
  ConnectedSingletonModel[method] = singletonError.bind(method)
}

export default ConnectedSingletonModel
