//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reactivator from 'vue-reactivator'
import { viewportSize } from 'vue-browser-state'
import { generateRandomId } from '@/assets/util/helpers'

const INITIAL_OFFSET = {
  top: '100%',
  left: 0,
  bottom: 'auto',
  right: 0,
}

export default {
  name: 'Dropdown',
  inheritAttrs: false,
  props: {
    disabled: Boolean,
    focusable: {
      type: Boolean,
      default: true,
    },
    seamless: Boolean,
  },
  mixins: [reactivator({ viewportSize })],
  data: () => ({
    active: false,
    isPlaced: false,
    alignment: 'left',
    offset: { ...INITIAL_OFFSET },
    contentId: `dropdown-content-${generateRandomId()}`,
  }),
  computed: {
    triggerId: (vm) => vm.$attrs.id || `dropdown-trigger-${generateRandomId()}`,
  },
  mounted() {
    this.unwatch = this.$watch(
      () => this.$route,
      () => {
        setTimeout(() => {
          this.close()
        }, 0)
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  methods: {
    toggle() {
      if (this.disabled) return
      this.active = !this.active
      this.$nextTick(() => {
        this.calculatePosition()
      })
    },
    focusout(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.close()
      }
    },
    close() {
      this.active = false
    },
    closeByKeyboard(e) {
      if (this.$refs.header.contains(document.activeElement)) {
        e.stopPropagation()
        this.close()
      }
    },
    toggleByClick(e) {
      if (!this.$refs.dropdown || !this.$refs.dropdown.contains(e.target)) {
        e.preventDefault()
        this.toggle()
      }
    },
    async calculatePosition() {
      // to prevent dropdown from being offscreen
      if (!this.isPlaced) {
        this.isPlaced = true

        let unwatch = this.$watch(
          () => this.viewportSize,
          () => {
            unwatch()
            this.isPlaced = false
          }
        )

        // Reset to original position to properly detect intended position
        this.offset = { ...INITIAL_OFFSET }
        this.alignment = 'left'
        await this.$nextTick()

        let el = this.$refs.dropdown
        let boundingRect = el.getBoundingClientRect()

        // prevent overflow right
        if (
          document.body.clientWidth <=
          boundingRect.left + boundingRect.width
        ) {
          this.offset.right = '15px'
          this.offset.left = 'auto'
          this.alignment = 'right'
        } else {
          this.offset.right = 'auto'
          this.offset.left = '0'
          this.alignment = 'left'
        }
      }
    },
  },
}
