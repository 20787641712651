import { render, staticRenderFns } from "./default-bare.vue?vue&type=template&id=378d8fd4&"
import script from "./default-bare.vue?vue&type=script&lang=js&"
export * from "./default-bare.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RateLimitPopUp: require('/builds/sasag-api/kundenbereich-frontend/components/modules/modal/RateLimitPopUp.vue').default,ConnectionIssuesPopUp: require('/builds/sasag-api/kundenbereich-frontend/components/modules/modal/ConnectionIssuesPopUp.vue').default,ServerIssuesPopUp: require('/builds/sasag-api/kundenbereich-frontend/components/modules/modal/ServerIssuesPopUp.vue').default})
