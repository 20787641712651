//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['error'],
  layout: 'default-bare',
  data() {
    return {}
  },
}
