import Vue from 'vue'

/**
 * Returns a Promise that resolves when the specified watched property fullfills
 * a specific condition (or rejects after a timeout).
 * The condition might be provided as a concrete value or as a callback which
 * checks the value whenever it changes.
 * If the condition is omitted altogether, the Promise resolves when the
 * watched expression becomes truthy.
 *
 * @param {string|function} name The expression to watch
 * @param {any} condition The condition for the watched expression to match
 * @param {number} timeout The timeout after which the watcher rejects (0 means never)
 * @returns Promise<void>
 *
 * @example
 * Checks when `this.foo` becomes truthy:
 * ```
 * this.$when('foo')
 * ```
 *
 * Checks when `this.answer` becomes 42:
 * ```
 * this.$when('answer', 42)
 * ```
 *
 * Checks when `this.temperature` drops below zero:
 * ```
 * this.$when('temperature', value => value < 0)
 * ```
 */
Vue.prototype.$when = function $when(name, condition = Boolean, timeout = 0) {
  const check =
    typeof condition === 'function' ? condition : (value) => value === condition

  return new Promise((resolve, reject) => {
    let timeoutId, unwatch

    if (timeout) {
      timeoutId = setTimeout(() => {
        unwatch()
        reject()
      }, timeout)
    }

    unwatch = this.$watch(
      name,
      (value) => {
        if (check(value)) {
          // Move to next tick because `unwatch` is not
          // yet defined if the check passes immediately
          this.$nextTick(unwatch)
          clearTimeout(timeoutId)
          resolve()
        }
      },
      { immediate: true }
    )
  })
}
