//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as validate from '@/assets/util/validate'

export default {
  model: {
    prop: 'visible',
    event: 'visibilitychange',
  },
  loadModels: ['user'],
  data: () => ({
    email: '',
    phone: '',
    phoneVisited: false,
    mobile: '',
    mobileVisited: false,
    username: '',
    error: undefined,
    loading: false,
    submitted: false,
    sentAgain: false,
  }),
  props: {
    visible: Boolean,
  },
  mapModels: ['user'],
  computed: {
    usernameErrorMessage: (vm) => validate.username.message(vm.username),
    phoneErrorMessage: (vm) => validate.phone.message(vm.phone),
    mobileErrorMessage: (vm) => validate.phone.message(vm.mobile),
    pending: (vm) =>
      vm.submitted || (vm.user?.emailVerificationPending ?? false),
  },
  async created() {
    await this.$when('$models.user.hasData')
    this.email = this.user.externalContactData.email
    this.phone = this.user.externalContactData.tel
    this.mobile = this.user.externalContactData.telMobile
    this.username = this.user.userAuth.username

    if (this.user.externalContactData.emailVerificationPending) {
      this.submitted = true
    }

    this.$services.auth.broadcast.onVerifyEmail(() => {
      if (this.pending && !this.error) {
        this.loading = true
        this.$models.user
          .load({ refresh: true })
          .then(() => {
            if (this.user.externalContactData.emailVerified) {
              this.$emit('visibilitychange', false)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    })
  },
  methods: {
    async updateUser() {
      try {
        this.error = undefined
        this.loading = true

        let data = {
          externalContactData: {
            tel: this.phone,
            email: this.email,
          },
        }

        if (this.user.userAuth.username !== this.username) {
          data.userAuth = {
            oldUsername: this.user.userAuth.username,
            newUsername: this.username,
          }
        }

        await this.$models.user.update(data)
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async save() {
      await this.updateUser()
      this.submitted = true
    },
    async sendAgain() {
      await this.updateUser()
      this.sentAgain = true
    },
    logout() {
      this.$emit('visibilitychange', false)
      this.$services.auth.logout()
    },
  },
}
