//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  data: () => ({
    validated: false,
  }),
  computed: {
    submitListener: (vm) => vm.$listeners.submit,
    proxiedListeners() {
      let { submit, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    validate({ silent = false } = {}) {
      let form = this.$refs.form
      if (form.checkValidity()) {
        return true
      } else {
        if (!silent) {
          // Request submit to mark invalid fields
          let submitButton = form.querySelector('[type=submit]')
          if (submitButton) {
            if (form.requestSubmit) {
              form.requestSubmit(submitButton)
            } else {
              // Safari does not support requestSubmit
              submitButton.click()
            }
          }

          // Scroll to and focus first invalid element
          let firstInvalidElement = form.querySelector(':invalid')

          firstInvalidElement.focus({
            preventScroll: true,
          })
          this.$scrollTo(firstInvalidElement)
        }

        return false
      }
    },
    async attemptSubmit(event) {
      this.validated = true

      // Wait one tick to allow things like submit-preventing <Alert>s to appear
      await this.$nextTick()

      let valid = this.$refs.form.checkValidity()

      this.$emit('attempt-submit', valid)

      if (valid) {
        if (this.submitListener) {
          this.submitListener(event)
        }
        if (!event.defaultPrevented) {
          this.$refs.form.submit()
        }
      } else {
        event.preventDefault()
        event.stopPropagation()

        this.validate()
      }
    },
  },
}
