import { Model } from '@orm'

export default class Summary extends Model {
  static private = true
  static entity = 'connections/summary'

  static fields() {
    return {
      duration: this.number(0),
      durationPercent: this.number(0),
      calls: this.number(0),
      callsPercent: this.number(0),
      cost: this.number(0),
      costPercent: this.number(0),
    }
  }
}
