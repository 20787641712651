import * as date from 'date-fns'
import { ConnectedModel } from '@orm'
import { naturalSort, parseProductName } from '@/assets/util/helpers'

const SORTED = Symbol()

export default class TV extends ConnectedModel {
  static entity = 'tv'
  static allowEmptyInstance = false

  static pull() {
    return this.api.get`/tv`
  }

  static fields() {
    return {
      aboChangeInProgress: this.boolean(),
      aboStart: this.string(),
      additionalBoxes: this.number(),
      additionalInformationAbo: this.attr(),
      additionalPackages: this.attr([], (packages) => {
        if (SORTED in packages) return packages

        let packagesCopy = [...packages]
        packagesCopy[SORTED] = true
        packagesCopy.sort((a, b) => naturalSort(a.name, b.name))

        return packagesCopy
      }),
      bookablePackages: this.attr(),
      boxes: this.number(),
      isCurrent: this.boolean(),
      name: this.string(),
      packageChangeInProgress: this.boolean(),
      priceAbo: this.number(),
      priceAdditionalPackages: this.number(),
      rentalArticles: this.attr(),
      serviceType: this.string(),
      serviceId: this.number(),
      totalPriceWithPackages: this.number(),
      pin: this.string(),
    }
  }

  get isConax() {
    return this.serviceType === 'Conax'
  }

  get isSappTv() {
    return this.serviceType === 'sappTV'
  }

  get isTvMotion() {
    return this.serviceType === 'TV Motion'
  }

  get services() {
    return Object.entries(this.additionalInformationAbo).filter(
      ([key, value]) => {
        // The 'apps' service is irrelevant for sasag according to Michi K.
        if (key === 'apps') return false

        // Disabled services should not be displayed
        if (value === false || value === 0) return false

        return true
      }
    )
  }

  get hasServices() {
    return this.services.length > 0
  }

  get hasBookablePackages() {
    return this.bookablePackages.length > 0
  }

  get hasAdditionalPackages() {
    return this.additionalPackages.length > 0
  }

  get hasPrice() {
    return this.priceAbo > 0
  }

  get nameParts() {
    return parseProductName(this.name)
  }

  get bandwidth() {
    return {
      down: Number(this.speed.downloadKbit) * 1000,
      up: Number(this.speed.uploadKbit) * 1000,
    }
  }

  get aboStartDate() {
    return date.startOfDay(date.parse(this.aboStart, 'y-MM-dd', new Date()))
  }

  get aboStartMonth() {
    return date.startOfMonth(this.aboStartDate)
  }

  /**
   * @see {@link https://doc.api.intra.sasag.ch/#post-/tv/sappTvPin}
   */
  static async setSappTvPin(serviceId, pin) {
    await $nuxt.$services.api.post(`/tv/sappTvPin`, {
      serviceId,
      pin,
    })
    await $nuxt.$models.tv.load({ refresh: true })
  }
}
