import { clearAllModels } from '@orm'
import { getRedirectUrl } from '@/assets/util/helpers'
import CrossChannel from '@/assets/util/CrossChannel'

export async function login(username, password) {
  // Clear all models on login
  await clearAllModels()

  // https://auth.nuxtjs.org/api/auth
  await $nuxt.$auth.loginWith('jwt', {
    data: { username, password },
  })

  broadcast.login()

  let urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('redirect')) {
    window.location.href = urlParams.get('redirect')
  } else {
    window.location.href = '/'
  }
}

export async function logout() {
  $nuxt.$store.commit('startLoggingOut')

  // Clear all models on logout
  await clearAllModels()
  await $nuxt.$auth.logout()
  broadcast.logout()

  window.location.href = '/'
}

export async function requestPasswordReset(username) {
  return await $nuxt.$axios.$post('/auth/reset', { username })
}

export async function resetPassword(token, username, password) {
  return await $nuxt.$axios.$put('/auth/reset', {
    token,
    username,
    password,
  })
}

export function logoutDetected() {
  broadcast.logout()

  let redirectUrl = getRedirectUrl()
  window.location.href = redirectUrl
}

export const broadcast = (() => {
  if (!process.browser) return

  let authChannel = new CrossChannel('auth')

  return {
    login() {
      authChannel.send({ type: 'login' })
    },
    logout() {
      authChannel.send({ type: 'logout' })
    },
    verifyEmail() {
      authChannel.send({ type: 'verifyEmail' })
    },
    onLogin(callback) {
      return authChannel.receive((message) => {
        if (message?.type === 'login') {
          callback()
        }
      })
    },
    onLogout(callback) {
      return authChannel.receive((message) => {
        if (message?.type === 'logout') {
          callback()
        }
      })
    },
    onVerifyEmail(callback) {
      return authChannel.receive((message) => {
        if (message?.type === 'verifyEmail') {
          callback()
        }
      })
    },
  }
})()
