// Make breakpoints available reactivly as a vue-reactivator implementation
// @see https://github.com/loilo/vue-reactivator

import Vue from 'vue'
import reactivator from 'vue-reactivator'
import { breakpoints } from '~/assets/breakpoints.yml'

let breakpointEntries = Object.entries(breakpoints)

function getSsrState() {
  // Match just smallest breakpoint for server-side rendering
  return breakpointEntries.map(([name], index) => [name, index === 0])
}

let matchers =
  process.browser &&
  Object.fromEntries(
    breakpointEntries.map(([name, width]) => [
      name,
      window.matchMedia(`(min-width: ${width}px)`),
    ])
  )

let matches =
  process.browser &&
  Vue.observable(
    Object.fromEntries(
      breakpointEntries.map(([name]) => [name, matchers[name].matches])
    )
  )

function getInitialState() {
  return matches
}

function listen() {
  let unwatchers = []
  for (let [name] of breakpointEntries) {
    let matcher = matchers[name]
    let handler = () => {
      matches[name] = matcher.matches
    }

    matcher.addEventListener('change', handler)

    unwatchers.push(() => matcher.removeEventListener('change', handler))
  }

  return () => unwatchers.forEach((unwatcher) => unwatcher())
}

let mixinMarker = Symbol.for('plugins/breakpoints')

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin(
    reactivator({
      breakpoint: {
        getSsrState,
        getInitialState,
        listen,
      },
    })
  )
}
