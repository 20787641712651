// vue-reactivator impmlementation for the hash,
// taking into account changes through vue-router

import Vue from 'vue'

export function getSsrValue() {
  return ''
}

let getHash = () => decodeURIComponent(window.location.hash.substr(1))

export function getInitialState() {
  return getHash()
}

export function listen(setState) {
  const hashListener = () => setState(getHash())

  window.addEventListener('hashchange', hashListener)
  let watcher = new Vue({
    created() {
      this.unwatchRoute = this.$watch(
        () => $nuxt.$route,
        () => {
          setState(getHash())
        }
      )
    },
    destroyed() {
      this.unwatchRoute()
    },
  })

  return () => {
    window.removeEventListener('hashchange', hashListener)
    watcher.$destroy()
  }
}
