import { ConnectedModel } from '@orm'

export default class CTA extends ConnectedModel {
  static entity = 'cta'

  // Set cache to never expire in a session, will only be re-fetched when BUILD_ID changes
  // This is safe as possible new links could only ever be used within a new build
  static cache = ['sessionStorage', { expires: Infinity }]

  static async pull() {
    let { cta } = await this.api.get`/static`

    return Object.entries(cta).map(([id, data]) => ({ id, ...data }))
  }

  static fields() {
    return {
      id: this.uid(),
      private: this.string(''),
      business: this.string(''),
      text: this.string(''),
    }
  }
}
