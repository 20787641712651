import VuexORM from '@vuex-orm/core'
import database from '@orm/database'

export const plugins = [VuexORM.install(database)]

export const state = () => ({
  loggingOut: false,
  rateLimitWarning: false,
  hintConnectionIssues: false,
  hintServerIssues: false,
})

export const mutations = {
  startLoggingOut(state) {
    state.loggingOut = true
  },
  setRateLimitWarning(state, value) {
    state.rateLimitWarning = value
  },
  setHintConnectionIssues(state, value) {
    state.hintConnectionIssues = value
  },
  setHintServerIssues(state, value) {
    state.hintServerIssues = value
  },
}
