//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Progress',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
}
