import { Database } from '@vuex-orm/core'
import * as models from '@orm/models-provider'

const database = new Database()

for (let model of Object.values(models)) {
  database.register(model)
}

export default database
