import Vue from 'vue'

/**
 * Evaluate a CSS length expression to its length in pixel
 *
 * @param {Element} element
 * @param {string} expression
 * @param {string} property
 * @return {number}
 */
function evaluteCssLength(element, expression, property = 'width') {
  let calculateWidth = () =>
    parseFloat(getComputedStyle(element).getPropertyValue(property))
  let preValue = element.style.getPropertyValue(property)
  let prePriority = element.style.getPropertyPriority(property)

  let preComputedValue = calculateWidth()

  element.style.setProperty(property, expression, 'important')

  if (element.style.getPropertyValue(property).length === 0) return NaN

  let result = calculateWidth()

  // Add an additional check if expression did not change result
  if (result === preComputedValue) {
    element.style.setProperty(
      property,
      `calc(${expression} + 1px)`,
      'important'
    )
    result = calculateWidth()
    element.style.setProperty(property, preValue, prePriority)

    if (result === preComputedValue) return NaN
  }

  element.style.setProperty(property, preValue, prePriority)

  return result
}

/**
 * Scroll an element into view if needed
 *
 * @param {string|Element} refOrElement
 * @param {object} options
 */
Vue.prototype.$scrollTo = function (
  refOrElement,
  {
    force = false,
    behavior = 'smooth',
    block = 'start',
    inline = 'nearest',
  } = {}
) {
  let element =
    typeof refOrElement === 'string' ? this.$refs[refOrElement] : refOrElement

  if (
    !element ||
    (!(element instanceof Element) && !(element?.$el instanceof Element))
  ) {
    console.warn('Cannot scroll to element, no element found: %o', refOrElement)
  }

  if (!(element instanceof Element)) {
    element = element.$el
  }

  let rect = element.getBoundingClientRect()

  let style = getComputedStyle(element)
  let scrollMarginTop = parseFloat(style.scrollMarginTop)
  let scrollMarginBottom = parseFloat(style.scrollMarginBottom)
  let offset = evaluteCssLength(element, 'var(--scroll-margin-offset)') || 0

  let startDistanceFromTopEdge = rect.top - scrollMarginTop - offset
  let startDistanceFromBottomEdge =
    window.innerHeight - rect.top - scrollMarginBottom - offset
  let endDistanceFromBottomEdge =
    window.innerHeight - rect.bottom - scrollMarginBottom - offset

  if (
    force ||
    startDistanceFromTopEdge < 0 ||
    startDistanceFromBottomEdge < 0 ||
    endDistanceFromBottomEdge < 0
  ) {
    element.scrollIntoView({ behavior, block, inline })
  }
}
