import { render, staticRenderFns } from "./BarButton.vue?vue&type=template&id=9be25fc4&scoped=true&"
import script from "./BarButton.vue?vue&type=script&lang=js&"
export * from "./BarButton.vue?vue&type=script&lang=js&"
import style0 from "./BarButton.vue?vue&type=style&index=0&id=9be25fc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9be25fc4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActionTrigger: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Unstyled/ActionTrigger.vue').default})
