import { render, staticRenderFns } from "./ContactDataPopUp.vue?vue&type=template&id=1e5ea37b&scoped=true&"
import script from "./ContactDataPopUp.vue?vue&type=script&lang=js&"
export * from "./ContactDataPopUp.vue?vue&type=script&lang=js&"
import style0 from "./ContactDataPopUp.vue?vue&type=style&index=0&id=1e5ea37b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e5ea37b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Alert: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Alert.vue').default,Input: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Form/Input.vue').default,Flex: require('/builds/sasag-api/kundenbereich-frontend/components/layout/Flex.vue').default,Button: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Buttons/Button.vue').default,Form: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Form/Form.vue').default,PageLoader: require('/builds/sasag-api/kundenbereich-frontend/components/layout/PageLoader.vue').default,PopUp: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Dialog/PopUp.vue').default})
