import { render, staticRenderFns } from "./Countdown.vue?vue&type=template&id=df36281c&scoped=true&"
import script from "./Countdown.vue?vue&type=script&lang=js&"
export * from "./Countdown.vue?vue&type=script&lang=js&"
import style0 from "./Countdown.vue?vue&type=style&index=0&id=df36281c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df36281c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DonutChart: require('/builds/sasag-api/kundenbereich-frontend/components/ui/DataVisualization/DonutChart.vue').default})
