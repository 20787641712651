import AbstractConnectionSummary from './_AbstractConnectionSummary'
import Summary from './connections/Summary'

export default class MobileConnectionSummary extends AbstractConnectionSummary {
  static entity = 'mobileConnectionSummary'

  static categories = [
    ...AbstractConnectionSummary.categories.filter(
      (category) => category !== 'other'
    ),
    'mobile-data',
    'mobile-data-roaming',
    'sms-mms',
    'sms-mms-roaming',
    'other',
  ]

  static fields() {
    return {
      ...super.fields(),
      Daten: this.attr(),
      'Roaming Daten': this.attr(),
      'SMS/MMS': this.attr(),
      'Roaming SMS/MMS': this.attr(),
    }
  }

  'sms-mms' = new Summary(this['SMS/MMS'])
  'sms-mms-roaming' = new Summary(this['Roaming SMS/MMS'])
  'mobile-data' = new Summary(this['Daten'])
  'mobile-data-roaming' = new Summary(this['Roaming Daten'])

  static async pull({ from, to, number }) {
    let summary = await this.api.get`/mobile/${number}/summary/${from}/${to}`

    return {
      ...summary.calls,
      Daten: summary.data['Daten'],
      'Roaming Daten': summary.data['Roaming Daten'],
      'SMS/MMS': summary.sms['SMS/MMS'],
      'Roaming SMS/MMS': summary.sms['Roaming SMS/MMS'],
    }
  }
}
