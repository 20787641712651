//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SpeechBubblesIcon from '~/assets/img/speech-bubbles.svg?inline'
import EnvelopeIcon from '~/assets/img/envelope.svg?inline'
import HeadsetIcon from '~/assets/img/headset.svg?inline'
import ExitIcon from '~/assets/img/exit.svg?inline'

export default {
  name: 'Footer',
  components: {
    SpeechBubblesIcon,
    EnvelopeIcon,
    ExitIcon,
    HeadsetIcon,
  },
}
