import Vue from 'vue'

let mixinMarker = Symbol.for('plugins/filters/currency')

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  let defaultCurrency = 'CHF'

  Vue.mixin({
    filters: {
      currency(value, { currency = defaultCurrency, round = false } = {}) {
        if (typeof value !== 'number') {
          let numberValue = parseFloat(value)
          if (isNaN(numberValue)) return value

          value = numberValue
        }

        if (round) {
          value = Math.round(value * 20) / 20
        }

        if (currency === false) {
          return value.toLocaleString('de-CH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        } else {
          if (currency === true) {
            currency = defaultCurrency
          }

          return new Intl.NumberFormat('de-CH', {
            style: 'currency',
            currency,
          }).format(value)
        }
      },
    },
  })
}
