import Vue from 'vue'

let mixinMarker = Symbol.for('plugins/title')

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    created() {
      // If component is a page (only pages have $metaInfo)
      if (this.$metaInfo) {
        this.$store.commit('title/setPath', this.$route.path)
        this.$store.commit('title/setTitle', this.$metaInfo.title)
        this.$store.commit(
          'title/setBreadcrumbTitle',
          this.$metaInfo.breadcrumbTitle ?? this.$metaInfo.title
        )
      }
    },
  })
}
