import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=a395f2d6&"
import script from "./Alert.vue?vue&type=script&lang=js&"
export * from "./Alert.vue?vue&type=script&lang=js&"
import style0 from "./Alert.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Form/Input.vue').default})
