export { Model } from '@vuex-orm/core'
export * from './ConnectedModel'
export * from './ConnectedSingletonModel'
export { default as ConnectedSingletonModel } from './ConnectedSingletonModel'
export * from './caching'

import ConnectedModel from './ConnectedModel'
export { ConnectedModel }

/**
 * Clear all models' records and cache
 **/
export async function clearAllModels() {
  for (let model of Object.values($nuxt.$models)) {
    if (model.prototype instanceof ConnectedModel) {
      await model.clearRecords()
      await model.clearCache()
    }
  }
}
