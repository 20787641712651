import { ConnectedSingletonModel } from '@orm'
import { diffObjects } from '@/assets/util/helpers'

export default class User extends ConnectedSingletonModel {
  static entity = 'user'
  static allowEmptyInstance = false

  static async pull() {
    let result = await this.api.get`/auth/me`

    // Set Sentry customer context
    if ($nuxt.$config.sentryEnabled) {
      $nuxt.$sentry.setUser({
        id: result.userAuth.id,
        username: result.userAuth.username,
      })
    }

    return result
  }

  static async pushUpdate(newData, oldData, { updateOptions }) {
    let diffedData = diffObjects(oldData, newData)

    // Enforce fully passing on external contact data as saving those
    // (even without changing them) can have side effects
    if (
      !diffedData?.externalContactData &&
      updateOptions.data.externalContactData
    ) {
      if (diffedData === null) {
        diffedData = {}
      }

      diffedData.externalContactData = updateOptions.data.externalContactData
    }

    // 'diffedData' diffs nested data, but usually complete data is needed from the first-level key onwards
    let fullDiffedData = {}
    for (let key in diffedData) {
      fullDiffedData[key] = {
        ...oldData[key],
        ...newData[key],
      }
    }

    // Cleanse phone number before updating to comply with API (which only accepts numbers)
    let cleansePhoneNumber = (numberString) =>
      numberString.replace(/^\+/, '00').replace(/[^0-9]/g, '')
    if (fullDiffedData.externalContactData) {
      fullDiffedData.externalContactData.tel = cleansePhoneNumber(
        fullDiffedData.externalContactData.tel
      )
      fullDiffedData.externalContactData.telMobile = cleansePhoneNumber(
        fullDiffedData.externalContactData.telMobile
      )
    }

    await this.api.post('/auth/me', fullDiffedData)
  }

  static fields() {
    return {
      externalContactData: this.attr(() => ({
        telMobile: ' ',
        tel: ' ',
        email: ' ',
        emailVerified: 'not',
      })),
      recipientAddress: this.attr(() => ({
        company: '',
        title: '',
        firstname: ' ',
        lastname: ' ',
        street: ' ',
        streetNumber: ' ',
        street2: '',
        zip: ' ',
        city: ' ',
        birthday: '',
        isChanging: false,
      })),
      billingAddress: this.attr(null, (value) =>
        value === null || !('company' in value) ? null : value
      ),
      billing: this.attr(() => ({
        billingMode: '',
        deliveryMethod: '',
      })),
      newsletterStatus: this.attr(() => ({
        mailAddressFromExternalContactData: '',
        registered: false,
      })),
      userAuth: this.attr(() => ({
        id: 0,
        username: '',
      })),
    }
  }

  get isBusinessUser() {
    return this.recipientAddress?.company.length > 0
  }

  get emailVerificationPending() {
    // TODO: This is a workaround for https://app.asana.com/0/1198170642329424/1200581046491762/f
    // and should be removed when the issue is resolved
    if ((this.externalContactData.emailAddressToVerify ?? '').length > 0) {
      return true
    }

    return this.externalContactData.emailVerified === 'pending'
  }

  get emailVerified() {
    // TODO: This is a workaround for https://app.asana.com/0/1198170642329424/1200581046491762/f
    // and should be removed when the issue is resolved
    if ((this.externalContactData.emailAddressToVerify ?? '').length > 0) {
      return false
    }

    return this.externalContactData.emailVerified === 'verified'
  }
}
