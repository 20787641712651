//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getRedirectUrl, supportsSelector } from '@/assets/util/helpers'
import { clearAllModels } from '@orm'

if (process.browser) {
  window.BUILD_ID = process.env.BUILD_ID
  window.DEPLOY_TAG = process.env.TAG
  window.DEPLOY_COMMIT = process.env.COMMIT
}

export default {
  loadModels: ['links', 'user', 'product'],
  data: () => ({
    needsContactData: false,
  }),
  mounted() {
    // Logged out in another tab, refresh to sync state
    this.unlistenBroadcastLogout = this.$services.auth.broadcast.onLogout(
      () => {
        this.unlistenBroadcastLogout()
        window.location.href = getRedirectUrl()
      }
    )

    // Handle missing files in /_nuxt (due to network errors,
    // likely because the user lost connection)
    this.$services.networkError.listen(() => {
      this.$store.commit('setHintConnectionIssues', true)
    })

    // Handle possible app updates
    this.unlistenUpdate = this.$services.update.listen(({ url }) => {
      // Check the current build ID
      fetch('/build')
        .then((response) => response.text())
        .then((newBuildId) => {
          if (BUILD_ID !== newBuildId) {
            // An update was actually pushed
            this.unlistenUpdate()

            // -> clear all models' cache and loaded data
            //    to avoid conflicts with possible changed model structures
            clearAllModels()

            // -> refresh the page to load latest resources
            window.location.reload()
          } else {
            // No update is available but the resource still is missing
            // -> report to Sentry
            if (this.$sentry) {
              this.$sentry.captureException(
                new Error(`Missing requested resource (404 error): ${url}`)
              )
            }
          }
        })
    })

    // Load user data and check whether contact data is complete
    this.$when('$models.user.hasData').then(() => {
      let user = this.$models.user.get()
      //this.needsContactData = user.externalContactData.email.length === 0
      this.needsContactData =
        user.externalContactData.email.length === 0 ||
        user.externalContactData.emailVerified === 'not'
    })

    // Add global class if :focus-visible is not supported
    if (!supportsSelector(':focus-visible')) {
      document.documentElement.classList.add('no-focus-visible')
    }

    // Add global class for iOS devices
    if (navigator.appVersion.includes('iPhone')) {
      document.documentElement.classList.add('ios')
    }
  },
  beforeDestroy() {
    this.unlistenBroadcastLogout()
    this.unlistenUpdate()
  },
}
