export const state = () => ({
  path: '',

  // The title of the current page as reflected in the header
  title: '',

  // The title of the current page as reflected in the breadcrumb
  breadcrumbTitle: '',

  // Titles for folders pages are nested in
  breadcrumbFolders: {
    profil: 'Mein Profil',
    telefonie: 'Telefonie',
    mobile: 'Mobile',
    internet: 'Internet',
    email: 'E-Mail',
    news: 'Neuigkeiten',
  },
})

export const mutations = {
  // Set the state's title, should be called by every page on visit
  setPath(state, path) {
    state.path = path
  },
  setTitle(state, title) {
    state.title = title
  },
  setBreadcrumbTitle(state, title) {
    state.breadcrumbTitle = title
  },
}
