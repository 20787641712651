import { ConnectedSingletonModel } from '@orm'
import Abo from './mobile/Abo'

export default class Mobile extends ConnectedSingletonModel {
  static entity = 'mobile'
  static allowEmptyInstance = false

  static pull() {
    return this.api.get`/mobile`
  }

  static fields() {
    return {
      abo: this.attr(
        () => [],
        (value) => {
          if (value.length > 0 && value[0] instanceof Abo) return value

          return value.map((record) => new Abo(record))
        }
      ),
      additionalPackages: this.attr(() => []),
      totalPriceWithPackages: this.number(0),
    }
  }
}
