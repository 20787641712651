//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 'data' is an array of objects with the following keys:
    //
    // id: string,
    // value: number,
    // background?: string,
    // foreground?: string,
    // label?: true|string
    //
    data: {
      type: Array,
      required: true,
    },
    size: {
      type: Number,
      default: 256,
    },
    stroke: {
      type: Number,
      default: 48,
    },
    labelSize: {
      type: [Number, String],
      default: 'auto',
    },
  },
  computed: {
    cx: (vm) => vm.size / 2,
    cy: (vm) => vm.size / 2,
    radius: (vm) => (vm.size - vm.stroke) / 2,
    actualLabelSize: (vm) =>
      vm.labelSize === 'auto' ? `${vm.size * 0.065}px` : `${vm.labelSize}px`,
    circumference: (vm) => 2 * Math.PI * vm.radius,
    autoHueStepSize: (vm) => 360 / vm.data.length,
    dataTotal: (vm) => vm.data.reduce((carry, item) => carry + item.value, 0),
    metadata() {
      let angleOffset = -90
      return this.data.map((item) => {
        let { x, y } = this.calculateTextCoords(item.value, angleOffset)
        // start at -90deg so that the largest segment is perpendicular to top
        let data = {
          degrees: angleOffset,
          textX: x,
          textY: y,
        }

        angleOffset = this.dataPercentage(item.value) * 360 + angleOffset

        return data
      })
    },
  },
  methods: {
    calculateStrokeDashOffset(value, circumference) {
      let strokeDiff = this.dataPercentage(value) * circumference
      return circumference - strokeDiff
    },
    calculateTextCoords(value, angleOffset) {
      // t must be radians
      // x(t) = r cos(t) + j
      // y(t) = r sin(t) + j

      let angle = (this.dataPercentage(value) * 360) / 2 + angleOffset
      let radians = this.degreesToRadians(angle)

      return {
        x: this.radius * Math.cos(radians) + this.cx,
        y: this.radius * Math.sin(radians) + this.cy,
      }
    },
    degreesToRadians(angle) {
      return angle * (Math.PI / 180)
    },
    dataPercentage(value) {
      return value / this.dataTotal
    },
    percentageString(value) {
      return `${Math.round(this.dataPercentage(value) * 100)}%`
    },
    returnCircleTransformValue(index) {
      return `rotate(${this.metadata[index].degrees}, ${this.cx}, ${this.cy})`
    },
  },
}
