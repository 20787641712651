import internetIcon from '~/assets/img/menu-internet.svg?raw'
import mobileIcon from '~/assets/img/menu-mobile.svg?raw'
import tvIcon from '~/assets/img/menu-tv.svg?raw'
import phoneIcon from '~/assets/img/menu-phone.svg?raw'
import emailIcon from '~/assets/img/menu-email.svg?raw'

export const state = () => ({
  // Can be used to directly link to the primary mobile abo from the manu,
  // thus skipping a request + redirect
  primaryMobileNumber: null,
})

export const getters = {
  items(state) {
    return [
      {
        label: 'Internet',
        route: '/internet/',
        icon: internetIcon,
        color: 'pink',
        showOnMobile: true,
        showOnDesktop: true,
      },
      {
        label: 'Mobile',
        route: `/mobile/${state.primaryMobileNumber ?? ''}`,
        icon: mobileIcon,
        color: 'orange',
        showOnMobile: true,
        showOnDesktop: true,
      },
      {
        label: 'TV',
        route: '/tv/',
        icon: tvIcon,
        color: 'green',
        showOnMobile: true,
        showOnDesktop: true,
      },
      {
        label: 'Telefonie',
        route: '/telefonie/',
        icon: phoneIcon,
        color: 'yellow',
        showOnMobile: true,
        showOnDesktop: true,
      },
      {
        label: 'E-Mail',
        route: '/email/',
        icon: emailIcon,
        color: 'blue',
        showOnMobile: true,
        showOnDesktop: true,
      },
    ]
  },
}

export const mutations = {
  setPrimaryMobileNumber(state, number) {
    state.primaryMobileNumber = number
  },
}
