// Part of auth detection & redirect

import { getRedirectUrl } from '@/assets/util/helpers'
import {
  LAST_REQUESTED_ROUTE,
  POST_LOGIN_REDIRECT,
} from '@/assets/util/last-requested-route'

export default function ({ store, route }) {
  if (route.name === 'login') {
    // If we're in login, and the last requested route is not null,
    // the 'auth' module has redirected us to the login page.
    // Therefore, we store the last requested route globally to
    // allow redirecting there after login.
    if (window[LAST_REQUESTED_ROUTE] !== null) {
      window[POST_LOGIN_REDIRECT] = window[LAST_REQUESTED_ROUTE]
    }
  }

  window[LAST_REQUESTED_ROUTE] = getRedirectUrl({ leadingSlash: false })
}
