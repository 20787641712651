import AbstractConnectionSummary from './_AbstractConnectionSummary'

// ATTENTION: This should be treated as an abstract class
export default class VoiceConnectionSummary extends AbstractConnectionSummary {
  static entity = 'voiceConnectionSummary'

  static pull({ from, to }) {
    return this.api.get`/voice/summary/${from}/${to}`
  }
}
