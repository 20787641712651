//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileIcon from '~/assets/img/profile.svg?inline'
import LogoutIcon from '~/assets/img/logout.svg?inline'
import homeIcon from '~/assets/img/menu-home.svg?raw'
import profileIcon from '~/assets/img/menu-profile.svg?raw'

export default {
  components: {
    ProfileIcon,
    LogoutIcon,
  },
  data: () => ({
    homeIcon,
    profileIcon,
  }),
  computed: {
    desktopMenu: (vm) =>
      vm.$store.getters['menu/items'].filter((item) => item.showOnDesktop),
  },
}
