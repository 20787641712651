import { ConnectedSingletonModel } from '@orm'

export default class Links extends ConnectedSingletonModel {
  static entity = 'links'

  // Set cache to never expire in a session, will only be re-fetched when BUILD_ID changes
  // This is safe as possible new links could only ever be used within a new build
  static cache = ['sessionStorage', { expires: Infinity }]

  static async pull() {
    let { urls, cta, 'news-css': newsCss } = await this.api.get`/static`

    // Don't use nested data!
    // Static links are accessed via the 'links' service which uses
    // a Proxy to provide dummy links until the '/static' endpoint is loaded
    // and therefore would fail when trying to access nested data.
    return {
      mailClientInstructions: urls['Anleitung-Mail'],
      modemInstructions: urls['Anleitung-Modems'],
      voiceBusinessPackages: urls['Business-Telefonie-Pakete'],
      contactForm: urls['Kontakt-Formular'],
      mobilePackages: urls['Mobile-Pakete'],
      tvPackages: urls['Sender-Pakete'],
      currentServiceInterruptions: urls['Störungen'],
      webmail: urls['Webmail'],
      news: urls['Kundencenternews'],
      newsCss,
      ctaEmail: cta.email.private,
      ctaInternet: cta.internet.private,
      ctaMobile: cta.mobile.private,
      ctaTelefonie: cta.telefonie.private,
      ctaTv: cta.tv.private,
      ctaEmailBusiness: cta.email.business,
      ctaInternetBusiness: cta.internet.business,
      ctaMobileBusiness: cta.mobile.business,
      ctaTelefonieBusiness: cta.telefonie.business,
      ctaTvBusiness: cta.tv.business,
    }
  }

  static fields() {
    return {
      mailClientInstructions: this.string('#'),
      modemInstructions: this.string('#'),
      voiceBusinessPackages: this.string('#'),
      contactForm: this.string('#'),
      mobilePackages: this.string('#'),
      tvPackages: this.string('#'),
      currentServiceInterruptions: this.string('#'),
      webmail: this.string('#'),
      news: this.string('#'),
      newsCss: this.string('#'),
      ctaEmail: this.string('#'),
      ctaInternet: this.string('#'),
      ctaMobile: this.string('#'),
      ctaTelefonie: this.string('#'),
      ctaTv: this.string('#'),
      ctaEmailBusiness: this.string('#'),
      ctaInternetBusiness: this.string('#'),
      ctaMobileBusiness: this.string('#'),
      ctaTelefonieBusiness: this.string('#'),
      ctaTvBusiness: this.string('#'),
    }
  }
}
