import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=64fb9f24&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
import style0 from "./Button.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Spinner.vue').default,Progress: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Progress.vue').default,ActionTrigger: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Unstyled/ActionTrigger.vue').default})
