// Handle app updates

import CrossChannel from '@/assets/util/CrossChannel'

let channel = new CrossChannel('404', { useServiceWorker: true })

export function listen(callback) {
  return channel.receive((data) => {
    callback(data)
  })
}
