//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reactivator from 'vue-reactivator'
import * as hash from '@/assets/util/hash'
import CloseIcon from '~/assets/img/popup-close.svg?inline'

export default {
  components: { CloseIcon },
  model: {
    prop: 'visible',
    event: 'visibilitychange',
  },
  mixins: [reactivator({ hash })],
  data: (vm) => ({
    imperativeVisible: vm.visible,
    context: undefined,
  }),
  props: {
    visible: Boolean,
    controlled: Boolean,
    closable: {
      type: [Object, Boolean],
      default: true,
    },
    path: String,
  },
  computed: {
    isVisible: (vm) =>
      Boolean(vm.controlled ? vm.visible : vm.imperativeVisible),
    matchingHash: (vm) => `/${vm.path}`,
    hasMatchingHash() {
      let firstPart = this.hash.slice(0, 1) + this.hash.slice(1).split('/')[0]

      return firstPart === this.matchingHash
    },
    hashPayload() {
      if (!this.hasMatchingHash) return undefined

      return this.hash.split('/')[2]
    },
    closableOptions() {
      let defaultCloseOptions = {
        escape: true,
        outsideClick: true,
        button: true,
      }

      if (this.closable === true) {
        return defaultCloseOptions
      }
      if (this.closable === false) {
        return {
          escape: false,
          outsideClick: false,
          button: false,
        }
      }

      return {
        ...defaultCloseOptions,
        ...this.closable,
      }
    },
  },
  watch: {
    hash() {
      if (this.path) {
        if (this.hasMatchingHash) {
          this.open(this.hashPayload)
        } else {
          this.close()
        }
      }
    },
    isVisible: {
      immediate: true,
      handler(visible, previousVisible) {
        document.documentElement.classList.toggle('has-popup', visible)

        if (visible) {
          document.addEventListener('keydown', this.escapePress)
          if (this.path) {
            if (!this.hasMatchingHash) {
              // Use window.history.pushState instead of this.$router.push
              // to avoid applying Nuxt's scroll behavior
              window.location.hash = `${window.location.pathname}#${this.matchingHash}`
            }
          }
        } else {
          this.cleanup()

          if (this.path && previousVisible && this.hasMatchingHash) {
            window.location.hash = ''
            window.history.replaceState(null, null, window.location.pathname)
          }
        }

        if (visible && this.closableOptions.button) {
          this.$nextTick(() => {
            this.$refs.closeButton?.focus()
          })
        }
      },
    },
  },
  mounted() {
    if (this.hasMatchingHash) {
      if (this.controlled) {
        this.$emit('visibilitychange', true)
        this.context = this.hashPayload
      } else {
        this.open(this.hashPayload)
      }
    }
  },
  destroyed() {
    this.cleanup()
  },
  methods: {
    open(context) {
      this.context = context
      this.toggle(true)
    },
    close() {
      this.toggle(false)
    },
    toggle(state) {
      if (this.controlled) {
        this.$emit('visibilitychange', state)
      } else {
        this.imperativeVisible = state
      }
    },

    cleanup() {
      document.removeEventListener('keydown', this.escapePress)
    },
    outsideClick(event) {
      if (
        this.closableOptions.outsideClick &&
        !event.composedPath().includes(this.$refs.popup)
      ) {
        this.close()
      }
    },
    escapePress(event) {
      if (
        this.closableOptions.escape &&
        event.key === 'Escape' &&
        !document.activeElement.matches(
          [
            'input:not([type=button]):not([type=submit]):not([type=reset])',
            'select',
            'textarea',
            '[contenteditable="true"]',
          ].join(', ')
        )
      ) {
        event.preventDefault()
        this.close()
      }
    },
  },
}
