//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    refresh() {
      window.location.reload()
    },
  },
}
