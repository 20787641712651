export let username = {
  validator: (value) => value.length === 0 || !/^[0-9]+$/.test(value),
  message(value) {
    return (
      !this.validator(value) &&
      'Der Benutzername darf nicht ausschliesslich aus Zahlen bestehen.'
    )
  },
}

export let phone = {
  errors: {
    SPECIAL_CHARACTERS: 1,
    TOO_SHORT: 2,
    TOO_LONG: 3,
  },
  rawValidator(value) {
    if (value.length === 0) return
    if (!/^\+?[0-9 /-]+$/.test(value)) throw this.errors.SPECIAL_CHARACTERS
    let onlyNumbers = value.replace(/^\+/, '00').replace(/[^0-9]/g, '')
    if (onlyNumbers.length < 10) throw this.errors.TOO_SHORT
    if (onlyNumbers.length > 18) throw this.errors.TOO_LONG
  },
  validator: (value) => {
    try {
      this.rawValidator(value)
      return true
    } catch {
      return false
    }
  },
  message(value) {
    try {
      this.rawValidator(value)
      return ''
    } catch (error) {
      switch (error) {
        case this.errors.SPECIAL_CHARACTERS:
          return 'In der Telefonnummer sind keine Sonderzeichen zulässig.'
        case this.errors.TOO_SHORT:
          return 'Die Telefonnummer muss mindestens aus 10 Ziffern bestehen.'
        case this.errors.TOO_LONG:
          return 'Die Telefonnummer darf höchstens aus 18 Ziffern bestehen.'
        default:
          throw error
      }
    }
  },
}
