import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=0e915c35&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Form: require('/builds/sasag-api/kundenbereich-frontend/components/ui/Form/Form.vue').default})
