const API_BASE = process.env.SASAG_API_BASE
const AXIOS_OPTIONS = Symbol()
const AXIOS_INSTANCE = Symbol()
const CREATE_AXIOS_INSTANCE = Symbol()
const COLLECT_REQUEST_ARGS = Symbol()

class ApiError extends Error {
  name = 'ApiError'

  constructor(code) {
    super(`The API request failed with code ${code}`)
    this.code = code
  }
}

/**
 * Comfort wrapper around Nuxt's Axios instance with
 * - automatic API base prepending
 * - automatic cancellation of unfinished requests
 * - a URL creation helper
 */
class Api {
  constructor({ baseURL = API_BASE, ...axiosOptions } = {}) {
    this[AXIOS_OPTIONS] = { baseURL, ...axiosOptions }
  }

  fork(axiosOptions = {}) {
    return new this.constructor({ ...this[AXIOS_OPTIONS], ...axiosOptions })
  }

  get axios() {
    if (!this[AXIOS_INSTANCE]) {
      this[AXIOS_INSTANCE] = this[CREATE_AXIOS_INSTANCE]()
    }

    return this[AXIOS_INSTANCE]
  }

  [CREATE_AXIOS_INSTANCE]() {
    let options = this[AXIOS_OPTIONS]
    let instance = $nuxt.$axios.create(options)

    instance.interceptors.request.use((config) => {
      this.cancelSource = instance.CancelToken.source()
      config.cancelToken = this.cancelSource.token

      return config
    })

    instance.interceptors.response.use(
      (response) => {
        // Unset the cancelSource as soon as a request finishes
        delete this.cancelSource

        return response
      },
      async (error) => {
        let errorCode = error?.response?.data?.errorcode

        if (!this.axios.isCancel(error)) {
          // User is no longer logged in
          if (error?.response?.status === 401 && !$nuxt.$auth.loggedIn) {
            $nuxt.$services.auth.logoutDetected()
            throw error
          }

          // Network error (no internet connection, API down, etc.)
          if (!error?.response?.status) {
            $nuxt.$store.commit('setHintConnectionIssues', true)
          }

          // Server error
          if (error?.response?.status >= 500) {
            $nuxt.$store.commit('setHintServerIssues', true)
          }

          // User exceeded rate limit
          if (error?.response?.status === 429) {
            $nuxt.$store.commit('setRateLimitWarning', true)
          }
        }

        if (errorCode) {
          throw new ApiError(errorCode)
        } else {
          throw error
        }
      }
    )

    return instance
  }

  [COLLECT_REQUEST_ARGS](args) {
    if (typeof args[0] === 'string') {
      return [args[0], ...args.slice(1)]
    } else {
      return [this.url(args[0], ...args.slice(1))]
    }
  }

  cancel() {
    if (this.cancelSource) {
      this.cancelSource.cancel()
      return true
    } else {
      return false
    }
  }

  /**
   * Template tag to create a clean API URL from a given template string
   *
   * @param {TemplateStringsArray} strings
   * @param {...any} values
   * @returns {string}
   *
   * @example
   * ```
   * url`/model/${null}/${'world'}?${{ page: 1, sort: null }}`
   * // -> "/model/world?page=1"
   * ```
   */
  url(strings, ...values) {
    strings = [...strings]
    let firstPart = strings.shift()

    return (
      // Join template string parts
      strings
        .reduce((carry, part, index) => {
          let value = values[index] ?? ''

          if (typeof value === 'object') {
            // Strip null, undefined and false
            let filteredValue = Object.entries(value).filter(
              ([, value]) => value != null && value !== false
            )

            value = String(new URLSearchParams(filteredValue))

            if (!carry.endsWith('?') && value.length > 0) {
              value = `&${value}`
            }
          } else {
            value = encodeURIComponent(value)
          }

          return carry + value + part
        }, firstPart)

        // Strip double slashes (probably created through interpolating empty values)
        .replace(/\/\/+/g, '/')

        // Strip slash before question mark
        .replace(/\/\?/, '?')

        // Strip trailing special characters
        .replace(/\/?\??$/, '')
    )
  }

  /**
   * Send a GET request to the API
   * Either receives a URL string and an options object or
   * a URL template string (see 'url' method)
   */
  get(...args) {
    let [url, options = {}] = this[COLLECT_REQUEST_ARGS](args)

    return this.axios.$get(url, options)
  }

  /**
   * Send a POST request to the API from a given template string
   * Either receives a URL string and an optional data and options object or
   * a URL template string (see 'url' method)
   */
  post(...args) {
    let [url, data = {}, options = {}] = this[COLLECT_REQUEST_ARGS](args)

    return this.axios.$post(url, data, options)
  }

  /**
   * Send a PUT request to the API from a given template string
   * Either receives a URL string and an optional data and options object or
   * a URL template string (see 'url' method)
   */
  put(...args) {
    let [url, data = {}, options = {}] = this[COLLECT_REQUEST_ARGS](args)

    return this.axios.$put(url, data, options)
  }

  /**
   * Send a PATCH request to the API from a given template string
   * Either receives a URL string and an optional data and options object or
   * a URL template string (see 'url' method)
   */
  patch(...args) {
    let [url, data = {}, options = {}] = this[COLLECT_REQUEST_ARGS](args)

    return this.axios.$patch(url, data, options)
  }

  /**
   * Send a DELETE request to the API from a given template string
   * Either receives a URL string and an optional data and options object or
   * a URL template string (see 'url' method)
   */
  delete(...args) {
    let [url, data = {}, options = {}] = this[COLLECT_REQUEST_ARGS](args)

    return this.axios.$delete(url, data, options)
  }
}

export default () => new Api()
