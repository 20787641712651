//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    // Handle missing files in /_nuxt (due to network errors,
    // likely because the user lost connection)
    this.$services.networkError.listen(() => {
      this.$store.commit('setHintConnectionIssues', true)
    })
  },
}
