//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
}
