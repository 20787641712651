import Vue from 'vue'

let mixinMarker = Symbol.for('plugins/filters/sup-number')

let supNumbers = '⁰¹²³⁴⁵⁶⁷⁸⁹'

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    filters: {
      supNumber: (number) =>
        String(number).replace(/[0-9]/g, (match) => supNumbers[Number(match)]),
    },
  })
}
