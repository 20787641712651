import { ConnectedModel } from '@orm'
import * as date from 'date-fns'

export default class InternetTrafficByDay extends ConnectedModel {
  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static entity = 'internetTrafficByDay'
  static allowEmptyInstance = false

  static pull({ from }) {
    return this.api.get`/internet/traffic/all/days/${from}`
  }

  static fields() {
    return {
      date: this.string(),
      summary: this.attr(),
    }
  }

  get datetime() {
    return date.startOfDay(date.parse(this.date, 'y-MM-dd', new Date()))
  }

  get download() {
    return this.summary.download.MB
  }

  get upload() {
    return this.summary.upload.MB
  }

  static async fetchCsv({ onProgress } = {}) {
    return await this.api.get(
      `/internet/traffic/all/days/${this.payload.from}`,
      {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
        onDownloadProgress(event) {
          if (typeof onProgress === 'function') {
            onProgress(event.loaded / event.total)
          }
        },
      }
    )
  }

  // Download the document in the background and generate a BLOB link
  static async generateDownloadLink() {
    return URL.createObjectURL(await this.fetchCsv())
  }

  static async download({ filename, onProgress } = {}) {
    if (typeof filename === 'undefined') {
      filename = `Internet-Traffic-${this.payload.from}.csv`
    }

    let blob = await this.fetchCsv({ onProgress })
    let url = URL.createObjectURL(blob)

    let a = document.createElement('a')
    a.download = filename
    a.href = url
    a.click()

    URL.revokeObjectURL(url)
  }
}
