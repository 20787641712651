//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reactivator from 'vue-reactivator'
import { online } from 'vue-browser-state'

export default {
  mixins: [reactivator({ online })],
  data: () => ({
    userIsOffline: false,
  }),
  computed: {
    visible: (vm) => vm.$store.state.hintConnectionIssues,
  },
  watch: {
    visible(visible) {
      if (visible) {
        // Set this once visible
        // We don't want this to be reactive to not confuse the user
        this.userIsOffline = !this.online
      }
    },
  },
  methods: {
    refresh() {
      window.location.reload()
    },
  },
}
