//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EyeIcon from '~/assets/img/eye.svg?inline'
import EyeSlashIcon from '~/assets/img/eye-slash.svg?inline'
import { generateRandomId } from '@/assets/util/helpers'

export default {
  inheritAttrs: false,
  components: {
    EyeIcon,
    EyeSlashIcon,
  },
  props: {
    label: String,
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: Object | Array | String | Number,
    },
    invalid: null,
    multiline: Boolean,
    noReveal: Boolean,
    required: Boolean,
    disabled: Boolean,
    pattern: [String, RegExp],
  },
  data: () => ({
    dirty: false,
    visited: false,
    revealed: false,
    hasFocus: false,
    nativeInvalidityMessage: '',
  }),
  computed: {
    id: (vm) => vm.$attrs.id ?? generateRandomId(),
    inputProps: (vm) => ({
      ...vm.proxiedAttrs,
      type: vm.type,
      disabled: vm.disabled,
      required: vm.required,
      placeholder: vm.placeholderLabel,
      pattern: vm.patternString,
      value: vm.value,
      id: vm.id,
    }),
    isInvalid: (vm) => vm.invalid || vm.nativeInvalidityMessage,
    isPasswordField: (vm) => vm.type === 'password',
    errorMessage: (vm) =>
      (typeof vm.invalid === 'string' ? vm.invalid : '') ||
      vm.nativeInvalidityMessage,
    showLabel() {
      if (['date'].includes(this.type)) return true

      let { value } = this
      return (this.hasFocus && value) || (value && value.length > 0)
    },
    patternString: (vm) =>
      vm.pattern instanceof RegExp ? vm.pattern.source : vm.pattern,
    placeholderLabel: (vm) => `${vm.label}${vm.required ? ' *' : ''}`,
    revealable: (vm) => vm.isPasswordField && !vm.noReveal,
    revealableAndRevealed: (vm) => vm.revealable && vm.revealed,
    revealButtonTitle: (vm) =>
      vm.revealed ? 'Passwort verstecken' : 'Passwort anzeigen',
    proxiedAttrs() {
      let { revealed, ...attrs } = this.$attrs
      return attrs
    },
    proxiedListeners() {
      let { input, ...listeners } = this.$listeners
      return listeners
    },
  },
  watch: {
    // Sync 'revealed' state with parent (for password fields)
    revealableAndRevealed: {
      handler(value) {
        this.$emit('update:revealed', value)
      },
      immediate: true,
    },

    invalid() {
      this.setCustomValidity()
    },

    // Sync 'focus' state with parent
    hasFocus: {
      handler(value) {
        this.$emit('update:focused', value)
      },
      immediate: true,
    },
  },
  mounted() {
    this.setCustomValidity()
  },
  methods: {
    setCustomValidity() {
      if (typeof this.invalid === 'string') {
        this.$refs.input.setCustomValidity(this.invalid)
      } else if (this.invalid) {
        this.$refs.input.setCustomValidity('Ungültige Eingabe')
      } else {
        this.$refs.input.setCustomValidity('')
      }
    },
    onInput(event) {
      this.$emit('input', event.target.value)
    },
    onBlur() {
      this.visited = true

      // Sync 'visisted' state with parent
      this.$emit('update:visited', true)
    },
    onInvalid(event) {
      let input = event.target
      if (input.validity.customError) {
        // If custom validity exists, remove it temporarily to get native message
        let customErrorMessage = input.validationMessage
        input.setCustomValidity('')
        this.nativeInvalidityMessage = input.validationMessage
        input.setCustomValidity(customErrorMessage)
      } else {
        this.nativeInvalidityMessage = input.validationMessage
      }
    },
    onChange() {
      this.dirty = true
      this.nativeInvalidityMessage = ''
    },

    // Sync 'valid' state with parent
    // Detecting validity this way is quite a hack, but it seems to be the only
    // dependable way. Only watching the 'invalid' event + value changes is not
    // sufficient as environmental conditions might change (e.g. conditional 'required')
    onAnimationstart(event) {
      if (event.animationName === 'detect-valid') {
        if (!this.hasFocus) {
          this.nativeInvalidityMessage = ''
        }

        this.$emit('update:valid', true)
      } else if (event.animationName === 'detect-invalid') {
        this.$emit('update:valid', false)
      }
    },
  },
}
