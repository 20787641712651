import Vue from 'vue'

let mixinMarker = Symbol.for('plugins/filters/error-message')

// If messages are commented out it's because they expose too
// much technical detail to provide value to the end user
let apiErrorMessages = {
  1: 'Ihre Anmeldung ist abgelaufen.',
  2: 'Ihre Eingaben waren ungültig.',
  3: 'Es konnte kein Ticket erstellt werden.',
  4: 'Ihr Konto hat keine zu Ihrer Wohnadresse zugehörige E-Mail Adresse hinterlegt.',
  5: 'Ihre Daten konnten nicht gespeichert werden (IDMS-Fehler). Bitte wenden Sie sich an unseren Support.',
  6: 'Der Newsletter konnte nicht abonniert werden.',
  7: 'Der Newsletter konnte nicht abbestellt werden.',
  8: 'Der Newsletter-Status wurde nicht geändert.',
  9: 'Ihr Konto hat keine zu Ihrer Wohnadresse zugehörige E-Mail Adresse hinterlegt.',
  10: 'Der Benutzername konnte nicht geändert werden.',
  11: 'Das bisherige Passwort wurde nicht korrekt eingegeben.',
  12: 'Die E-Mail zum Zurücksetzen des Passworts konnte nicht verschickt werden.',
  13: 'Benutzername existiert nicht.',
  14: 'Die externe E-Mail Adresse ist nicht korrekt.',
  15: 'Passwort, Benutzername oder Zurücksetzungs-Token fehlt.',
  16: 'Ungültiges oder abgelaufenes Zurücksetzungs-Token. Bitte fordern Sie eine neue Zurücksetzungs-Mail an.',
  17: 'Die Aktualisierung des Passworts ist fehlgeschlagen.',
  18: 'Sie haben keine Rechnungsadresse hinterlegt, Aktualisierung ist nicht zulässig.',
  19: 'Die PDF-Datei zur Rechnung konnte nicht erstellt werden.',
  20: 'Es wurde keine passende Rechnung gefunden.',
  21: 'Ein offenes Ticket zur Änderung Ihrer Rechnungsadresse existiert bereits.',
  22: 'Ein offenes Ticket zur Änderung Ihrer Wohnadresse existiert bereits.',
  23: 'Anmeldung fehlgeschlagen, da Sie keine aktiven Abos haben.',
  24: 'Sie haben keine aktiven Abos in dieser Kategorie.',
  25: 'Netzwerkfehler beim Versenden der Bestätigungsmail. Bitte versuchen Sie es erneut.',
  26: 'Ungültiger oder abgelaufener Bestätigungslink. Bitte fordern Sie über den E-Mail-Änderungsvorgang einen neuen Link an.',
  // 27: 'Cannot set the attribute for "E-Mail verifiziert"!',
  28: 'Zu dem gewählten Benutzernamen liegt uns keine E-Mail Adresse vor.',
  29: 'Diese E-Mail Adresse ist bereits vergeben.',
  30: 'Die E-Mail-Adresse ist ungültig, da der Teil nach dem "@" nicht von sasag bereitgestellt wird.',
  31: 'Das Passwort für diese E-Mail-Adresse konnte nicht geändert werden.',
  32: 'Die Weiterleitung für diese E-Mail-Adresse konnte nicht geändert werden.',
  33: 'Das Weiterleitung für diese E-Mail-Adresse konnte nicht entfernt werden.',
  34: 'Ungültige Spamschutz-Stufe.',
  35: 'Die Spamschutz-Stufe für diese E-Mail-Adresse konnte nicht geändert werden.',
  36: 'Die E-Mail-Adresse konnte nicht gelöscht werden.',
  37: 'Es konnte keine neue E-Mail-Adresse hinzugefügt werden.',
}

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    filters: {
      errorMessage(error, fallbackMessage) {
        let defaultMessage = (error) =>
          `Ein unbekannter Fehler ist aufgetreten${
            error?.code ? ` (Fehlercode ${error.code})` : ''
          }.`

        if (fallbackMessage && typeof fallbackMessage !== 'function') {
          let fallbackMessageString = fallbackMessage
          fallbackMessage = () => fallbackMessageString
        }

        if (error?.name === 'ApiError') {
          return (
            apiErrorMessages[error.code] ??
            fallbackMessage?.(error) ??
            defaultMessage(error)
          )
        }

        return error?.message ?? fallbackMessage?.() ?? defaultMessage(error)
      },
    },
  })
}
