import formatPhoneNumber from '@/assets/util/format-phone-number'
import Vue from 'vue'

let mixinMarker = Symbol.for('plugins/filters/phone-number')

if (!Vue[mixinMarker]) {
  Vue[mixinMarker] = true

  Vue.mixin({
    filters: {
      phoneNumber: (numberString) => formatPhoneNumber(numberString),
    },
  })
}
