import AbstractConnectionDetails from './_AbstractConnectionDetails'

// A slice of connections for a particular month
// Solved as a subclass as it needs to maintain an independent payload
export default class MobileConnectionDetails extends AbstractConnectionDetails {
  static entity = 'mobileConnectionDetails'

  static async pullData({ from, to, sort, page, perPage, number }) {
    return await this.api.get`/mobile/${number}/cdr/${from}/${to}?${{
      page,
      perPage,
      sort,
    }}`
  }

  static fields() {
    return {
      ...super.fields(),
      type: this.number(),
      dataKb: this.string(),
    }
  }

  get isCall() {
    return this.type === 0
  }

  get isMessage() {
    return this.type === 2
  }

  get isData() {
    return this.type === 1
  }

  // Data in bytes
  get data() {
    return this.dataKb.length === 0 ? 0 : parseFloat(this.dataKb) * 1024
  }

  static get rawConnectionsUrl() {
    return this.api
      .url`/mobile/${this.payload.number}/cdr/${this.payload.from}/${this.payload.to}`
  }

  static get csvFilename() {
    if (this.payload.to && this.payload.to !== this.payload.from) {
      return `Mobile-Verbindungen-${this.payload.number}-von-${this.payload.from}-bis-${this.payload.to}.csv`
    } else {
      return `Mobile-Verbindungen-${this.payload.number}-in-${this.payload.from}.csv`
    }
  }
}
